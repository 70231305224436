import { store, axios } from '../services'

import Logout from '../../views/Logout.vue'
import ResetPassword from '../../views/Auth/ResetPassword.vue';
import Login from "../../views/Login.vue";

export default [
    {
        path: "/logout",
        name: "Logout",
        component: Logout
    },
    {
        path: "/reset-password/:token/:ente",
        name: "ResetPassword",
        component: ResetPassword,
        meta: { external: true },
        beforeEnter(to, _, next) {


            store.dispatch("Ente/fetchCliente", to);

            next();
        }
    },
    {
        path: "/login/:ente",
        name: "Login",
        component: Login,
        meta: { translateBasedOnCliente: true },
        beforeEnter(to, from, next) {
            if (!['local', 'esse3', 'ldap', 'form'].includes(store.state.Ente.configs.login_pubblico.metodo)) {
                if (from.name == "DatiUtente" || from.name == 'servizi_brevi.DatiUtente') {
                    sessionStorage.setItem(
                        "state",
                        JSON.stringify(store.state)
                    );
                }
                const redirect =
                    from.path.includes("welcome") || from.path == "/"
                        ? "/" + to.path.split("/")[2]
                        : from.path;
                localStorage.setItem("redirect", redirect);

                // next()
                axios
                    .post(`/api/auth/${store.state.Ente.cliente.Codice}/login`)
                    .then(res => (window.location = res.data.redirect));
            } else {
                next();
            }
        }
    },
]