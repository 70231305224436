<template>
  <modal>
    <template slot="header">
      <p class="text-danger h4">
        {{ $t("snodo-login.title") }}
        
      </p>
    </template>
    <template slot="body">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-5">
            <p class="text-lead">{{ $t("snodo-login.description") }}</p>
          </div>
          <div class="col-6">
            <router-link :to="{ name: 'Login' }">
              <e-button class="btn-block ">{{$t('menu.login')}}</e-button>
            </router-link>
          </div>
          <div class="col-6">
            <e-button @click="continua" class="btn-block" outlined>{{ $t('buttons.continua') }}</e-button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations } from "vuex";
import Modal from "../components/Modal";
export default {
  name: "SnodoLogin",
  components: {
    Modal,
  },

  methods: {
    ...mapMutations({
      setProfiloAssegnato: "Utils/SET_PROFILO_ASSEGNATO",
    }),
    continua() {
      this.setProfiloAssegnato(true);
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-mask[data-v-701ac82d] {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
}
</style>