import store from '../../store/index';

export default function RedirectHome({ to, next }) {
    if (to.matched.some(({ name }) => name === 'Prenota')) {
        if (isEmpty(store.state.Entry)) {
            return next({ name: 'NotFound', params: { ente: to.params.ente } });
        }
        return next();
    }
}

function isEmpty(obj) {
    return Object.values(obj).toString().replace(/,/g, "") === "";
}