<template>
  <modal @close="$emit('closed')" data-cypress="area-modal">
    <template slot="body">
      <div>
        {{ $t("login.recupera_password.title") }}
        <p class="m-4 text-left">
          {{ $t("login.recupera_password.description") }}
        </p>
        <div class="alert alert-danger mt-2 unfocus" ref="messages" tabindex="0" v-if="showInputUtente">
          <ul class="list-unstyled">
            <li>
              {{ $t("login.recupera_password.multiple_users") }}
            </li>
          </ul>
        </div>
        <label for="email">Email</label>
        <input type="text" placeholder="email" v-model="email" name="email" />
        <label for="username" v-if="showInputUtente">Username</label>
        <input name="username" type="text" placeholder="username" v-model="username" v-if="showInputUtente" />
        <p v-if="error" class="mt-2 text-danger">
          {{ $t("login.recupera_password.user_not_found") }}
        </p>
        <e-button @click="inviaForm" @keyup.enter="inviaForm" :dontDisable="true"
          class="btn btn-lg btn-primary btn-block mt-5">{{
            $t("buttons.next")
          }}</e-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      email: "",
      username: "",
      error: null,
      showInputUtente: false,
    };
  },
  props: {
    currentAccount: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.email = this.currentAccount;
  },
  computed: {
    ...mapState({
      cliente: (state) => state.Ente.cliente.Codice
    })
  },
  methods: {
    ...mapMutations({
      setMessage: "Utils/SET_MESSAGES",
    }),
    inviaForm() {
      //resetto errore se era già presente
      this.$http
        .post("/api/auth/recupera-password", {
          email: this.email,
          username: this.username,
          cliente: this.cliente,
        })
        .then((r) => {
          //caso in cui ci siano più utenti con lo stesso email
          if (r.data.error) {
            return (this.showInputUtente = true);
          }
          // chiudo modale e mostro messaggio di successo
          this.setMessage({
            name: "RECUPERATA",
            message: this.$t("login.recupera_password.success"),
          });
          this.$emit("closed");
        });
    },
  },
};
</script>

<style></style>