<template>
  <div>
    <!-- Mappa -->
    <div class="mt-3 ml-1">
      <p>
        <span v-if="area.address" class="font-weight-bold">{{
            $t("form.area.address")
        }} </span>
        <span v-html="area.address"></span>

        <a data-cypress="area-map" v-if="map" @click="showModal = true" @keyup.enter="showModal = true"
          class="text-danger cursor-p ml-2 ">{{ $t("form.area.show-map") }}</a>
      </p>
    </div>


    <!-- Modale mappa -->
    <modal v-if="showModal" @close="showModal = false" data-cypress="area-modal">
      <template slot="body">
        <iframe v-if="map" :title="$t('form.area.address')" :src="map" width="100%" height="400px" frameborder="10"
          allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </template>
    </modal>
  </div>
</template>

<script>

import Modal from "../../components/Modal";
//@group Form
//Mostra e gestisce Select Area
export default {
  name: "area-map",
  components: {
    Modal,
  },
  props: {
    area: {
      type: Object,
      default: null,
    },

  },
  computed: {
    map() {
      return this.area.google_map_link
        ? this.area.google_map_link.split('"')[1]
        : null;
    }
  },
  data() {
    return {


      showModal: false,

    };
  },




};
</script>

<style>
.cursor-p {
  cursor: pointer;
}
</style>