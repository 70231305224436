<template>
    <div v-if="Object.keys(errors).length > 0 && open">
        <div class="alert alert-danger mt-2 unfocus" ref="error" tabindex="0">
            <ul class="list-unstyled">
                {{
                    getMessage()
                }}
            </ul>
            <div class="d-flex mt-4" v-if="home">
                <button
                    class="btn btn-outline-danger ml-auto"
                    @click="goHome"
                    @keyup.enter="goHome"
                >
                    {{ $t("buttons.close") }}
                </button>
            </div>
            <template v-else>
                <div class="d-flex mt-4" v-if="close">
                    <button
                        class="btn btn-outline-danger ml-auto"
                        @click="clearErrors"
                        @keyup.enter="clearErrors"
                    >
                        {{ $t("buttons.close") }}
                    </button>
                </div>
                <div class="d-flex mt-4" v-else>
                    <button
                        @click="goBack"
                        @keyup.enter="goBack"
                        class="btn btn-outline-danger ml-auto"
                    >
                        {{ $t("buttons.back") }}
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    name: "ErrorBanner",
    props: {
        close: {
            type: Boolean
        },
        backTo: {
            type: String
        }
    },
    computed: {
        ...mapState({
            errors: state => state.Utils.errors
        }),
        home() {
            //get keys of errors
            let keys = Object.keys(this.errors);
            // keys contains test
            if (keys.includes("home")) {
                return true;
            }
            return false;
        }
    },
    data() {
        return {
            open: true
        };
    },
    mounted() {
        this.focus();
    },
    watch: {
        errors() {
            this.focus();
        }
    },
    methods: {
        ...mapMutations({
            clearErrors: "Utils/CLEAR_ERRORS"
        }),
        getMessage() {
            const data = this.errors.data;
            const message = this.errors.message;

            if (this.$t(message) != message) return this.$t(message, data);
            if (
                this.$t(`server.errors.${message}`) ==
                `server.errors.${message}`
            )
                return this.$t("server.errors.general_error");
            return this.$t(`server.errors.${message}`, data);
        },
        focus() {
            this.$refs.error.focus();
        },
        goHome() {
            this.clearErrors();
            this.$router.push({ name: "HomeCliente" });
        },
        goBack() {
            if (!this.backTo) {
                this.$router.go(-1);
            } else {
                this.$router.push({ name: this.backTo });
            }
        }
    }
};
</script>

<style scoped>
.unfocus:focus {
    outline: none;
    border-color: #d9364f;
    box-shadow: none;
}
</style>
