import { routesNames } from './router/routes/routes';
import EButton from '../src/components/layout/EButton';
import ELink from '../src/components/layout/ELink';
import Accordion from '../src/components/layout/Accordion.vue';
import Message from "../src/components/layout/Message";
import EasyInput from '../src/components/inputs/EasyInput'
import ErrorBanner from "../src/components/ErrorBanner.vue";
import MessageBanner from "../src/components/MessageBanner";
import Modal from "../src/components/Modal";
import LoadingSpinner from '../src/components/layout/LoadingSpinner';
import Feedback from '../src/components/feedback/Feedback';
import Card from '../src/components/Card';
import Http from "../src/services/Http";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Select2 from '../src/components/inputs/Select2';
import CustomSelect from "../src/components/inputs/CustomSelect.vue";
import TableHeader from "../src/components/layout/Table/table_header.vue";

import DatePicker from './components/inputs/DatePicker/DatePicker';

import store from "./store";

import { clickOutside } from "../src/directives/click-outside";
import { mapMutations, mapState } from "vuex";

import { knowsColors } from './mixins/knowsColors'
import { knowsLanguage } from './mixins/knowsLanguage';
import { secondsToString } from './mixins/secondsToString';

const MyPlugin = {};
export default MyPlugin.install = function(Vue) {
    Vue.use(DatePicker);
    Vue.component('EButton', EButton)
    Vue.component('ELink', ELink)
    Vue.component("message", Message);
    Vue.component("EasyInput", EasyInput);
    Vue.component("ErrorBanner", ErrorBanner);
    Vue.component("MessageBanner", MessageBanner);
    Vue.component('Modal', Modal);
    Vue.component('LoadingSpinner', LoadingSpinner)
    Vue.component('feedback', Feedback)
    Vue.component('Card', Card);
    Vue.component('Accordion', Accordion);
    Vue.component('Select2', Select2);
    Vue.component('custom-select', CustomSelect);
    Vue.component('table-header', TableHeader);

    Vue.component('vue-phone-number-input', VuePhoneNumberInput);

    //direttive
    Vue.directive("click-outside", clickOutside);
    Vue.prototype.$routes = routesNames;
    //mixins globali
    Vue.mixin(knowsColors);
    Vue.mixin(knowsLanguage);
    Vue.mixin(secondsToString);
    Vue.mixin({
        computed: {
            ...mapState({
                // loading: state => state.Utils.loading,
                isLogged: state => state.User.isLogged,
                errors: state => state.Utils.errors,
                messages: state => state.Utils.messages,
                configs: state => state.Ente.configs,
                _locale: state => state.Language.locale,
                cliente: state => state.Ente.cliente,
                kiosk: state => state.Utils.kiosk,
                primaria: state => state.Utils.primaria,

            }),
        },
        watch: {
            _locale(value) {
                this.$http.defaults.headers.common['X-APP-LOCALE'] = value
            },
            cliente(value) {
                this.$http.defaults.headers.common['X-CLIENTE'] = value.id
            }
        },
        methods: {
            ...mapMutations(
                {
                    setMessage: "Utils/SET_MESSAGES",
                    setError: "Utils/SET_ERRORS",
                }
            )
        }
    });
    Vue.directive('click-outside', {
        bind: function(el, binding, vnode) {
            el.clickOutsideEvent = function(event) {
                // here I check that click was outside the el and his children
                if (!(el == event.target || el.contains(event.target))) {
                    // and if it did, call method provided in attribute value
                    vnode.context[binding.expression](event);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent)
        },
        unbind: function(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent)
        },
    });
    Vue.config.errorHandler = (err, vm, info) => {
        if (process.env.VUE_APP_DEBUG && process.env.VUE_APP_DEBUG != false) {
            console.error(err, vm, info)
        }

        // TODO: Perform any custom logic or log to server
        //caso form
        if (err == 'Error: Errore Form') {
            store.commit("Utils/SET_ERRORS", {
                home: 'server.errors.reload_form'
            });
        }

    };
    Vue.prototype.$http = Http();
}

