<template>
    <div>
        <input
            type="text"
            :value="modelValue"
            @input="updateValue"
        />
    </div>
</template>

<script>
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js";
import $ from "jquery";

export default {
    name: "date-picker",
    props: {
        modelValue: {
            type: String
        },
        multiple: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            datePicker: null
        };
    },
    emits: ["update:modelValue"],
    mounted() {
        const vm = this;
        this.datePicker = $(this.$el).find("input");
        console.log(this.modelValue);

        this.datePicker
            .datepicker({
                ...this.options,
                multidate: this.multiple,
                language: this.lang
            })
            .on("changeDate", function() {
                console.log(vm.datePicker.val());
                vm.$emit("update:modelValue", () => vm.datePicker.val());
            });
    },

    methods: {
        updateValue($event) {
            this.$emit("update:modelValue", $event.target.value);
        }
    }
};
</script>
