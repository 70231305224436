<template>
    <div>
        <div v-if="!loading" class="h-100-vh">
            <div
                class="w-full blue d-flex flex-column align-items-center"
                v-if="deferredPrompt"
            >
                {{ $t("pwa.download") }}
                <div>
                    <button @click.prevent="dismiss" @keyup.enter="dismiss">
                        {{ $t("pwa.rifiuta") }}
                    </button>
                    <button @click.prevent="install" @keyup.enter="install">
                        {{ $t("pwa.accetta") }}
                    </button>
                </div>
            </div>
            <nav-bar />

            <message-banner v-if="messages" />
            <div
                :class="[
                    $route.name === 'NotFound'
                        ? 'container-fluid p-0'
                        : 'container-md'
                ]"
            >
                <router-view id="main" role="main" />
            </div>
        </div>

        <loading-spinner
            style="height: 100vh; background-color: #fbfbfb"
            v-else
            :showText="true"
        />

        <cookie-law
            v-if="cookieLaw"
            theme="dark-lime"
            :buttonText="$t('cookie.accetta')"
            :message="$t('cookie.message')"
        >
        </cookie-law>
        <snodo-login v-if="snodoLogin" />
        <easy-footer></easy-footer>
    </div>
</template>

<script>
import NavBar from "./components/layout/NavBar";
// import Modal from "./components/Modal.vue";
import EasyFooter from "./components/layout/EasyFooter";
import MessageBanner from "./components/MessageBanner";
import Cookies from "js-cookie";
const CookieLaw = () => "vue-cookie-law";
// import Tour from "./components/Tour";
import { mapState } from "vuex";
import LoadingSpinner from "./components/layout/LoadingSpinner.vue";
import SnodoLogin from "./views/SnodoLogin";
import { colors } from "./mixins/colors.js";

export default {
    name: "App",
    mixins: [colors],
    components: {
        NavBar,
        CookieLaw,
        // Tour,
        MessageBanner,
        LoadingSpinner,
        // Modal,
        EasyFooter,
        SnodoLogin
    },
    data() {
        return {
            deferredPrompt: null,
            showContatti: false,
            infoModal: false
        };
    },

    computed: {
        ...mapState({
            loading: state => state.Utils.loading,
            messages: state => state.Utils.messages,
            errors: state => state.Utils.errors,
            ente: state => state.Ente,
            infoCliente: state => state.Ente.info,
            profiloAssegnato: state => state.Utils.profiloAssegnato
        }),
        cookieLaw() {
            return process.env.VUE_APP_COOKIE_BANNER;
        },
        snodoLogin() {
            if ("login_pubblico" in this.ente.configs) {
                return (
                    this.ente.configs.login_pubblico.snodo_login == 1 &&
                    this.profiloAssegnato === false
                );
            }
            return false;
        },
        version: () => {
            return process.env.VUE_APP_VERSION;
        }
    },
    created() {
        if (navigator.userAgent.match(/(iPad|iPhone|iPod touch);.*CPU.*OS/i)) {
            const selects = document.querySelectorAll("select");
            for (let i = 0; i < selects.length; i++) {
                selects[i].appendChild(document.createElement("optgroup"));
            }
        }
    },

    methods: {
        async dismiss() {
            Cookies.set("add-to-home-screen", null, {
                expires: 15,
                sameSite: "none"
            });
            this.deferredPrompt = null;
        },
        async install() {
            this.deferredPrompt.prompt();
            Cookies.set("add-to-home-screen", null, {
                expires: 10000,
                sameSite: "none"
            });
        },

        showInfoModal() {
            this.infoModal = true;
        }
    }
};
</script>

<style>
:root {
    --test-var: "red";
    --custo-0: "ciao";
}
.time-pill {
    border: 2px solid black;
    border-radius: 16px;
    color: black;
    font-size: inherit;
    padding: 0.3rem 0.8rem;
    text-align: center;
    max-width: 129px;
    min-width: 123px;
    margin: 0;
    text-indent: 0.01px;
    text-overflow: "";
    text-align: start;
    -webkit-appearance: button;
}

.h-100-vh {
    min-height: 100vh;
}

:root {
    --form-padding: 1rem 1.4rem;
    --calendar-bg: #eee;

    --gray-ligth: #505a5e85;
    --form-border-radius: 10px;

    --ticket-border-radius: 20px;
    --ticket-border-color: #eee;
}

@media print {
    @page {
        size: 150mm 297mm; /* Imposta le dimensioni della pagina a 210mm x 297mm */
        /* Puoi impostare anche altre proprietà come 'landscape' per la modalità landscape */
    }
    .w-print-full {
        width: 100%;
    }
    .w-50 {
        width: 100%;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }
}

.form-padding {
    padding: var(--form-padding);
}

html {
    height: 100vh;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

#app {
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
}

@media screen and (max-width: 600px) {
    :root {
        --form-padding: 1rem 1.4rem;
    }

    .form-padding {
        min-height: 100%;
    }
}

.contatti {
    right: 10px;
    bottom: 10px;
    height: 60px;
    width: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.dropdown-c {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #19191a;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid transparent;
    -webkit-box-shadow: 0 20px 30px 5px rgb(0 0 0 / 5%);
    box-shadow: 0 20px 30px 5px rgb(0 0 0 / 5%);
    z-index: 8;
}

.info-button {
    background-color: #505a5e;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    bottom: 0px;
    right: 0;
    margin: 1rem;
    padding: 1rem;
    cursor: pointer;
}

.Password__strength-meter {
    width: 100%;
}

.Password {
    width: 100%;
}

.Password__strength-meter {
    margin: 0;
}

.skipLinks {
    white-space: nowrap;
    margin: 1em auto;
    top: 0;
    position: fixed;
    left: 50%;
    margin-left: -72px;
    opacity: 0;
    z-index: 100;
}

.skipLinks:focus {
    opacity: 1;
    background-color: white;
    padding: 0.5em;
    border: 1px solid black;
}
</style>
