import {i18n} from "../i18n";

export const knowsLanguage = {
    computed: {
        lang() {
            const locales = process.env.VUE_APP_LOCALES.split(';');
            const lang = locales.find(locale => i18n.locale.split('_')[i18n.locale.split('_').length - 1].includes(locale.toLowerCase()))
            return lang;
        }
    }
};
