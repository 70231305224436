const StatisticheAccesso = () =>
    import(
        /* webpackChunkName: "StatisticheAccesso-find" */ "../../views/StatisticheAccesso.vue"
    );

export default [{
    path: "/:ente/statistiche-di-accesso",
    name: "StatisticheAccesso",
    component: StatisticheAccesso,
    meta: { translateBasedOnCliente: true, keepAlive: true }
},

]