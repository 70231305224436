<template>
  <div
    class="alert alert-secondary position-relative"
      v-if="$t(messageKey) != messageKey && $t(messageKey) != ''"
  >
    <span v-if="closeBtn" class="close-allert-btn" @click="$emit('close')" @keyup.enter="$emit('close')"
      ><i class="fas fa-times"></i
    ></span>
    <div
        v-if="$t(messageKey) != messageKey && $t(messageKey) != '' "
      role="alert"
      v-html="$t(messageKey)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    messageKey: {
      type: String,
      required: true,
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
};
</script>

<style>
</style>