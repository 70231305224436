<template>
    <a
        :class="withoutBackground ? '' : 'btn btn-primary'"
        :download="fileName ? fileName : null"
        :href="href"
        :style="withoutBackground ? '' : styleObject"
    >
        <slot></slot>
    </a>
</template>

<script>
import { mapState } from "vuex";
import { colors } from "../../mixins/colors";

export default {
    mixins: [colors],

    name: "ELink",
    props: {
        href: {
            type: String,
            required: true
        },
        fileName: {
            type: String
        },
        outlined: {
            type: Boolean
        },
        color: {
            type: Number,
            required: false
        },
        withoutBackground : {
            type : Boolean,
            required : false
        }

    },
    computed: {
        ...mapState({
            colors: state => state.Ente.colors.sfondo
        }),
        computedColor() {
            return this.color ? this.colors[this.color] : this.colors[0];
        },
        styleObject() {
            const style = {
                boxSizing: "border-box",
                border: "2px solid " + this.computedColor,
                backgroundColor: this.computedColor,
                color: this.getContrastText(this.computedColor)
            };

            if (this.outlined) {
                style.backgroundColor = "#ffffff";
                style.color = this.computedColor;
                return style;
            }

            return style;
        }
    }
};
</script>

<style></style>
