import Cookie from "js-cookie";
import axios from "axios";
import store from '../../store/index'

export default function EvadiConQr(to, next) {
    const user = Cookie.get('user');
    if (!Cookie.get('evasione')) return next();
    if (!user && to.name != 'Login' && to.name != 'Home' && to.name != 'redirect') {
        return next({
            name: 'Login',
            params: {
                ente: to.params.ente
            }
        });
    }
    if (to.name != 'redirect' && to.name != 'Login' && to.name != 'Home') {
        axios.get(`/api/qr/evadi/${store.state.Ente.cliente.id}/${Cookie.get('evasione')}/${Cookie.get('user')}`)
            .then((res) => {
                console.log(res)
                if (res.data.evase.length === 0) {
                    store.commit("Utils/SET_MESSAGES", {
                        name: 'Non hai prenotazioni da evadere',
                        message: 'evasione.prenota'
                    });
                    Cookie.remove('evasione')

                    return next({
                        name: "servizi",
                        params: {
                            ente: to.params.ente
                        },
                        query: {
                            servizio: res.data.servizio,
                            area: res.data.area,
                        }
                    })
                } else {
                    store.commit("Utils/SET_MESSAGES", {
                        name: 'Prenotazioni Evase',
                        message: ['evasione.evase', res.data.evase.length]
                    });
                }


                Cookie.remove('evasione')
            })

        return next();

    }
    return next();
}

