import {i18n} from "../../i18n";
import store from "../../store/index";

export default function translateBasedOnCliente(to) {

    if (to.matched.some(record => record.meta.translateBasedOnCliente)) {
        i18n.locale = to.params.ente + "_" + store.state.Language.locale;
        i18n.fallbackLocale = store.state.Language.locale;
    }
}
