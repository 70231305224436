import Cookie from "js-cookie";
import axios from "axios";
import Ente from "./Ente";
export default {
    namespaced: true,
    state: {
        isLogged: false,
        loggedJwt: null,
        reservations: {}
    },
    getters: {},
    mutations: {
        SET_IS_LOGGED(state) {
            state.isLogged = true;
        },
        SET_LOGGEDJWT(state, value) {
            state.loggedJwt = value;
        },
        LOGOUT(state, data) {
            Cookie.remove("user");
            Cookie.remove('hide_logout_button');
            localStorage.removeItem("redirect");
            localStorage.removeItem("greeting-uses");
            state.isLogged = false;
            state.loggedJwt = null;
            state.reservations = {};
            let redirectUrl = process.env.VUE_APP_LOGOUTREDIRECT_TO_PAGE
                ? `/${process.env.VUE_APP_BASE}/logout}`
                : `/${process.env.VUE_APP_BASE}/${Ente.state.cliente.Codice}`;

            if (data.data.redirect) redirectUrl = data.data.redirect;
            document.location.href = redirectUrl;
        },
        SET_RESERVATIONS(state, value) {
            state.reservations = value;
        }
    },
    actions: {
        async getReservations({ commit, rootState }) {
            const res = await axios.post(
                `api/reservations/exceptions/${rootState.Ente.cliente.ID}/${rootState.Entry.servizio.id}`,
                {
                    user: Cookie.get("user")
                }
            );
            commit("SET_RESERVATIONS", res.data.exceptions);
        },
        async logoutAction({ commit, state, rootState }) {
            let provider = state.loggedJwt.provider;

            const res = await axios
                .get(`/api/auth/${provider}/logout/${rootState.Ente.cliente.Codice}`)
            commit("LOGOUT", res);
        }
    }
};
