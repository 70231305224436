<template>
  <div class="wrapper position-relative">
    <div class="select-2 mt-4">
      <div class="custom" tabindex="0" @blur="showOptions = false" ref="custom" v-click-outside="call">
        <input aria-label="search_input" @keydown.enter="focus" type="text" class="select-2--search-bar form-select"
          v-model="search" :placeholder="placeholder" :disabled="!computedOBJ || disabled || computedOBJ.length == 0"
          :class="{ 'd-md-none': selected }" @doubleClick="focusSelect" @focus="focus" ref="input"
          autocomplete="nope" />

        <div class="options" v-if="showOptions">
          <div class="option" tabindex="0" @click="submit($event)" @keydown.enter="submit($event)"
            v-for="element in computedOBJ" :key="element[id]" :value="element[id]" :selected="computedOBJ.length == 1"
            v-html="element[description] == 'altro' ? $t('altro') : element[description]">
          </div>
        </div>
      </div>
      <div class="select w-100 position-relative" data-cypress="select-wrapper">
        <select :disabled="!computedOBJ || disabled || computedOBJ.length == 0"
          class="form-select select-2--select mt-4" :class="{ 'd-md-none': !selected }" :aria-label="placeholder"
          @change="submit($event)" :ref="name" data-cypress="customSelect-select">
          <option value="1" :selected="!selected" disabled>
            {{ placeholder }}
          </option>
          <option v-if="all" value="all">{{ $t("select_all") }}</option>
          <option v-for="element in computedOBJ" :key="element[id]" :value="element[id]"
            :selected="selectedId == element[id]"
            v-html="element[description] == 'altro' ? $t('altro') : element[description]">

          </option>
          <optgroup class="d-md-none"></optgroup>
        </select>
        <span role="button" v-if="selected || search" tabindex="0" class="close-btn d-none d-md-block"
          @click="resetSelect" @keyup.enter="resetSelect"><span class="sr-only">{{
            $t('custom_select.reset_select')
          }}</span><i class="fas fa-times" style="margin-right: 6px"
            :style="showOptions ? 'margin-top : 29px;' : ''"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
//Select custom ispirata a select2
//@group Inputs
export default {
  props: {
    //placeholder della select
    placeholder: {
      type: String,
      required: true,
    },
    //Elemento da loopare nella select
    object: { type: Array },
    //Chiave descrittiva del singolo elemento dell'oggetto loopato
    description: {
      type: String,
      required: true,
    },
    //Identificativo del singolo elemento loopato
    id: {
      type: String,
      required: true,
    },
    //Mostra lable "tutti"
    all: {
      type: Boolean,
      default: false,
    },
    //Nome della select, crea una $ref all'alemento
    name: {
      type: String,
      required: true,
    },
    //Disabilita la select se true
    disabled: {
      type: Boolean,
      default: false,
    },

    urlValue: {
      type: [String, Boolean],
      default: false
    }
  },
  events: ["selected", "reset", "clear"],
  data() {
    return {
      search: "",
      computedOBJ: null,
      selected: false,
      showOptions: false,
      selectedId: null,
      oldSearch: "",
    };
  },

  mounted() {
    //copia dell'oggetto prop sortandolo per description
    if (this.object) {
      this.computedOBJ = [
        ...this.object.sort((a, b) =>
          a[this.description] > b[this.description]
            ? 1
            : b[this.description] > a[this.description]
              ? -1
              : 0
        ),
      ];
    }
    this.setDefaultValue()
  },
  watch: {

    object(value, oldValue) {
      if (value && value.length === 1) {
        this.$emit("selected", value);
        this.selectedId = value[0][this.id];
        this.selected = true;
        this.showOptions = false;
      }
      if (JSON.stringify(value) != JSON.stringify(oldValue)) {
        this.selected = false;
      }
      this.setDefaultValue();

      this.computedOBJ = value;
    },
    search(value) {
      if (value != "") {
        this.showOptions = true;
      }
      this.computedOBJ = this.object.filter((e) =>
        e[this.description].toLowerCase().includes(value.toLowerCase())
      );
    },
    computedOBJ(value) {
      // se presente un solo valore
      if (value && value.length == 1) {
        this.$emit("selected", value);
        this.selectedId = value[0][this.id];
        this.selected = true;
        this.showOptions = false;
      }
      if (this.computedOBJ.length == 0 && this.search) {
        this.oldSearch = this.search;
        this.resetSelect();
        this.placeholder = this.$t('custom_select.no_results') + this.oldSearch;
      }
    },
  },

  methods: {
    call() {
      this.showOptions = false;
    },
    focus() {
      // this.$refs.custom.focus();
      this.showOptions = true;
    },

    resetSelect() {
      this.search = "";
      this.selected = false;
      this.$emit("clear");
    },
    focusSelect() {
      this.$refs[this.name].focus();
    },
    submit(e) {
      if (e.target.classList.contains("option")) {
        this.showOptions = false;
        this.selected = true;
        let selected = Array.from(this.$refs[this.name].options).filter(
          (el) => {
            return el.text.trim() == e.target.innerText.trim();
          }
        );
        let id = selected[0].value;
        this.$refs[this.name].options[0].innerText = selected[0].innerText;

        const eleme = this.object.filter((el) => el[this.id] == id);

        this.$emit("selected", eleme);
        this.selectedId = eleme[0][this.id];

        return;
      }
      if (this.all == true && e.target.value == "all") {
        this.$emit("selected", this.object);
        this.selected = true;

        return;
      }
      const eleme = this.object.filter((el) => el[this.id] == e.target.value);
      this.$emit("selected", eleme);

      this.selected = true;
    },
    setDefaultValue() {
      if (this.$route.query[this.urlValue]) {
        this.selected = true
        const id = this.$route.query[this.urlValue]
        const eleme = this.object.filter((el) => el[this.id] == id);
        if (eleme.length === 0) return;
        this.$emit("selected", eleme);
        this.selectedId = eleme[0][this.id];
      }
    },

  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
}

.select-2 {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
}

.select-2--search-bar {
  position: absolute;
  border: none;
  /* width: 50%; */
  /* margin-left: 6px; */
  /* height: 70%; */
  border: 1px solid rgb(199, 198, 198);
  border-radius: 6px;
  /* padding: 1rem; */
  margin-top: -10px;
  /* transform: translateY(-50%); */
}

.close-btn {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 70%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: white;
  color: rgb(129, 129, 129);
  font-size: 1.2rem;
}

@media screen and (max-width: 766px) {
  .select-2--search-bar {
    display: none;
  }
}

.options {
  width: 99.4%;
  max-height: 200px;
  overflow-y: scroll;
  margin: auto;
  /* border: 2px solid #86b7fe; */
  box-shadow: 0 4px 0 0.25rem rgb(13 110 253 / 25%);
  /* padding: 0 1rem; */
  position: absolute;
  left: 2px;
  top: 60px;
  z-index: 1000;
  background: white;
  /* nascondo lo scroll nella select */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.option:hover {
  background-color: #86b7fe;
}
/* nascondo lo scroll nella select */
.options::-webkit-scrollbar {
  display : none;
}

.options .option {
  padding: 1px 1rem;
  font-size: 0.9rem;
}

.disabled {
  background: #000000;
}
</style>
