import { mapState } from "vuex";
export const colors = {
    computed: {
        ...mapState({
            colors: (state) => state.Ente.colors.sfondo,
        }),
        textColor() {
            return this.getContrastText(this.colors[0])
        }
    },
    methods: {
        getContrastText(hexcolor) {
            hexcolor = hexcolor.replace("#", "");
            var r = parseInt(hexcolor.substr(0, 2), 16);
            var g = parseInt(hexcolor.substr(2, 2), 16);
            var b = parseInt(hexcolor.substr(4, 2), 16);
            var yiq = (r * 299 + g * 587 + b * 114) / 1000;
            return yiq >= 128 ? "black" : "white";
        },
    },
};
