<template>
    <card :cardName="'servizi_brevi'" :link="$routes.SERVIZI_BREVI.SERVIZI" :style="style" :size="config.size"
        :color="config.color">
        <template v-slot:icon>
            <img src="@/assets/images/gestisci_prenoatzione.svg" class="my-3 p-2" style="width: 300px" alt="icona" />
        </template>

        <template v-slot:title>
            <h5>
                {{ $t("servizi_brevi.title") }}
            </h5>
        </template>
        <template v-slot:description>
            <div class="card-text text-sans-serif">
                {{ $t("servizi_brevi.description") }}
            </div>
        </template>
</card>
</template>

<script>
export default {
    props: {
        config: {
            required: true,
            type: Object
        }
    },
    computed: {
        style() {
            return {
                gridArea: this.config.position,
                order: this.config.order,
            }
        }
    }
}
</script>

<style></style>