import http from "../services/Http";
const $http = http();
export default {
    namespaced: true,
    state: {
        manage: null,
    },
    getters: {},
    mutations: {
        SET_MANAGE(state, value) {
            state.manage = value;
        },
    },
    actions: {
        async fetchManage({ commit }, payload) {
            // commit("Utils/TOOGLE_LOADING", null, {root: true});
            const res = await $http.get(
                `api/entry/manage/${payload.codice}?chiave=${payload.chiave}`
            );

            return new Promise(function(resolve, reject) {
                if (res.data.success == false) {
                    commit("Utils/SET_ERRORS", res);
                    reject();
                } else {
                    // res.data.primaria = this.chiave;
                    commit("SET_MANAGE", res.data);
                    // commit("Utils/TOOGLE_LOADING", null, {root: true});
                    resolve();
                }
            });
        },
    }
};
