export default {
    namespaced: true,
    state: {
        raggruppamenti: null,
        area: null,
        sede: null,
        servizio: null,
        durata: null,
        data: null,
        end: null,
        datiUtente: null,
        risorsa: null,
        oldServizio: null,
        retEntry: null,
        autoSelectDate: false,
        qrCode: null,
        numberOfReservations: 1,
        old_number: null
    },
    mutations: {
        RESET_RETURN_ENTRY(state) {
            state.retEntry = null;
        },
        SET_RESERVATIONS_NUMBER(state, value) {
            if (value != state.old_number)
                state.old_number = state.numberOfReservations;

            state.numberOfReservations = value;
        },
        SET_QR_CODE(state, value) {
            state.qrCode = value;
        },
        RESET_ENTRY(state) {
            state.raggruppamenti = null;
            state.area = null;
            state.sede = null;
            state.servizio = null;
            state.durata = null;
            state.data = null;
            state.end = null;
            state.datiUtente = null;
            state.retEntry = null;
            state.risorsa = null;
            state.numberOfReservations = 1;
            state.old_number = null;
        },

        SET_DATE(state, value) {
            if (value == null) {
                state.data = null;
                state.end = null;
            } else {
                state.data = value.date;
                state.end = value.end;
            }
        },
        SET_RAGGRUPPAMENTO_SERVIZI(state, value) {
            state.raggruppamenti = value;
        },
        SET_AREA(state, value) {
            state.area = value;
        },
        SET_SEDE(state, value) {
            state.sede = value;
        },
        SET_SERVIZIO(state, value) {
            state.servizio = value;
            if (value) {
                state.autoSelectDate =
                    !!value.booking_public_method ||
                    state.servizio.servizio_breve;
                if (state.oldServizio == null) {
                    state.oldServizio = value.id;
                }
                if (value.durata != 0) {
                    state.durata = value.durata;
                }
            } else {
                state.autoSelectDate = null;
                state.oldServizio = null;
                state.durata = null;
            }
        },
        SET_RISORSA(state, value) {
            state.risorsa = value;
        },
        SET_DURATA(state, value) {
            state.durata = value;
        },
        SET_DATI_UTENTE(state, value) {
            state.datiUtente = value;
        },
        SET_RETENTRY(state, value) {
            if (state.retEntry === null) state.retEntry = [];
            state.retEntry.push(value);
        },
        SET_ENTRY(state, value) {
            state.raggruppamenti = value.raggruppamenti;
            state.area = value.area;
            state.sede = value.sede;
            state.servizio = value.servizio;
            state.durata = +value.durata;
            state.data = new Date(value.data);
            state.end = new Date(value.end);
            state.datiUtente = value.datiUtente;
            state.risorsa = value.risorsa;
            state.oldServizio = value.oldServizio;
            state.retEntry = value.retEntry;
            state.autoSelectDate = value.autoSelectDate;
            state.numberOfReservations = 1;
        }
    }
};
