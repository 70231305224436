<template>
  <div class="form-padding d-flex flex-column h-100">
    <!-- Servizi -->
    <message messageKey="messaggi.servizi_brevi" />

    <div :class="'order-' + configs.campi_portale.servizi">
      <custom-select
        ref="raggruppamenti_servizi"
        @clear="clear('raggruppamento_servizi')"
        v-if="visible.raggruppamenti_servizi"
        :placeholder="$t('form.servizio.raggruppamento')"
        id="nome"
        :object="data.raggruppamentiServizi"
        :disabled="loading.raggruppamentiServizi"
        description="nome"
        name="selectRaggServizi"
        @selected="selectRaggServizi"
      />

      <div :class="'order-' + configs.campi_portale.servizi">
        <custom-select
          ref="servizi"
          @clear="clear('servizi')"
          v-if="+visible.servizi"
          :disabled="
            loading.servizi ||
            (raggruppamentiObbligatori.servizi &&
              entry.raggruppamento_servizi == null)
          "
          :placeholder="$t('form.servizio.tipologia')"
          id="id"
          :object="data.servizi"
          description="type"
          name="selectServizio"
          @selected="selectServizio"
        />
        <div
          v-if="entry.servizio && entry.servizio.description"
          class="mt-3 ml-1"
        >
          <div
            v-html="entry.servizio.description"
            class="service-description"
          ></div>
        </div>
      </div>
    </div>
    <!-- Servizi -->
    <!-- area -->

    <div :class="'order-' + configs.campi_portale.aree">
      <custom-select
        ref="raggruppamento_aree"
        @clear="clear('raggruppamento_aree')"
        v-if="visible.raggruppamenti_aree"
        :disabled="loading.raggruppamentiAree"
        :placeholder="$t('form.raggruppamento')"
        id="ID"
        :object="data.raggruppamentiAree"
        name="selectRaggArea"
        description="Nome"
        @selected="selectRaggAree"
      />
      <div :class="'order-' + configs.campi_portale.aree">
        <custom-select
          ref="aree"
          @clear="clear('area')"
          v-if="visible.aree"
          :disabled="
            loading.aree ||
            (raggruppamentiObbligatori.aree &&
              entry.raggruppamento_aree == null)
          "
          :placeholder="$t('form.area.sede')"
          id="id"
          :object="data.aree"
          name="selectArea"
          description="area_name"
          @selected="selectArea"
        />
        <Area v-if="entry.area" :area="entry.area" />
      </div>
    </div>
    <!-- area -->

    <e-button
      ref="continua"
      data-tour-tep="7"
      @click="pushToCalendar"
      @keyup.enter="pushToCalendar"
      :class="{ disabled: !entry.servizio || !entry.area }"
      class="btn btn-lg btn-primary mt-4 confirm-button order-10"
      data-cypress="continua"
    >
      {{ $t("buttons.next") }}</e-button
    >
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CustomSelect from "../inputs/CustomSelect.vue";
import Area from "../form/Area";
import Cookies from "js-cookie";

export default {
  name: "SelectPage",
  components: {
    CustomSelect,
    Area,
  },
  computed: {
    ...mapState({
      configs: (state) => state.Ente.configs,
      cliente: (state) => state.Ente.cliente,
    }),
    raggruppamentiObbligatori() {
      return {
        servizi:
          this.configs.campi_portale.raggruppamento_servizi_obbligatorio > 0,
        aree: this.configs.campi_portale.raggruppamento_aree_obbligatorio > 0,
      };
    },
    orders() {
      let orders = {
        servizi: +this.configs.campi_portale.servizi,
        aree: +this.configs.campi_portale.aree,
      };
      if (orders.aree == orders.servizi) {
        orders.aree = orders.servizi + 1;
      }
      return orders;
    },
    visible() {
      return {
        raggruppamenti_servizi:
          this.configs.campi_portale.raggruppamento_servizi != 0,
        servizi: this.configs.campi_portale.servizi != 0,
        raggruppamenti_aree:
          this.configs.campi_portale.raggruppamento_aree != 0,
        aree: this.configs.campi_portale.aree != 0,
      };
    },
  },
  data() {
    return {
      data: {
        raggruppamentiServizi: null,
        servizi: null,
        raggruppamentiAree: null,
        aree: null,
      },
      entry: {
        raggruppamento_servizi: null,
        servizio: null,
        raggruppamento_aree: null,
        area: null,
      },
      loading: {
        raggruppamentiServizi: false,
        servizi: false,
        raggruppamentiAree: false,
        aree: false,
      },
    };
  },
  watch: {
    entry: {
      handler: function (val) {
        //allegerisco il cookie inserendo solo le informazioni che mi servono in SelectPageController
        // nome,id,ID
        const newVal = {};
        Object.keys(val).map((k) => {
          if (val[k] != null) {
            let obj = {};
            if ("id" in val[k]) obj.id = val[k].id;
            if ("nome" in val[k]) obj.nome = val[k].nome;
            if ("ID" in val[k]) obj.ID = val[k].ID;
            newVal[k] = obj;
          }
        });
        Cookies.set("entry-filters", newVal);
        this.$http.defaults.withCredentials = true;
      },
      deep: true,
    },
    //watch su valori entry
    "entry.raggruppamento_servizi"(value) {
      if (value == null) return;
      // const primo = Object.keys(this.orders).reduce((a, b) => this.orders[a] < this.orders[b] ? a : b);
      if (this.entry.servizio) {
        this.entry.servizio = null;
        if (this.orders.servizi < this.orders.aree) {
          this.entry.raggruppamento_aree = null;
          this.entry.area = null;
        }
      }
      this.getServizi();
    },
    "entry.servizio"(value) {
      if (value == null) return;
      if (this.orders.aree == 0 || this.orders.aree === undefined) {
        //chiama servizi con parametro first
        return this.getAree(true).then(() => {
          this.entry.area = this.data.aree[0];
        });
      }
      if (this.orders.servizi < this.orders.aree) {
        if (this.visible.raggruppamenti_aree) {
          this.entry.area = null;
          return this.getRaggruppamentiAree().then(this.getAree());
        }
        this.entry.area = null;
        this.getAree();
      }
    },
    "entry.raggruppamento_aree"(value) {
      if (value == null) return;
      if (this.entry.area) {
        this.entry.area = null;
        if (this.orders.aree < this.orders.servizi) {
          this.entry.raggruppamento_servizi = null;
          this.entry.servizio = null;
        }
      }
      this.getAree();
    },
    "entry.area"(value) {
      if (value == null) return;
      if (this.orders.servizi == 0 || this.orders.servizi === undefined) {
        //chiama servizi con parametro first
        return this.getServizi(true).then(() => {
          this.entry.servizio = this.data.servizi[0];
        });
      }
      if (this.orders.aree < this.orders.servizi) {
        if (this.visible.raggruppamenti_servizi) {
          this.entry.servizio = null;
          return this.getRaggruppamentiServizi().then(this.getServizi());
        }
        this.entry.servizio = null;
        this.getServizi();
      }
    },
    //watch sui valori data (select)

    "data.servizi"() {
      if (this.orders.servizi < this.orders.aree) {
        this.clearSelect("area");
      }
    },

    "data.aree"() {
      if (this.orders.aree < this.orders.servizi) {
        this.clearSelect("servizio");
      }
    },
  },
  mounted() {
    Cookies.remove("entry-filters");
    //trova quale select valorizzare per prima
    // order minore
    let orders = this.orders;
    //se tutti gli ordini sono uguali imposto il default
    if (orders.servizi == orders.area) {
      orders = {
        servizi: 1,
        area: 2,
      };
    }
    //rimuovi select non visibile
    for (let key in orders) {
      if (orders[key] == 0) {
        delete orders[key];
      }
    }
    //recupera la prima
    const firstSelect = Object.keys(orders).reduce((key, v) =>
      orders[v] < orders[key] ? v : key
    );
    //switch su key per chiamare la funzione
    switch (firstSelect) {
      case "servizi":
        if (this.visible.raggruppamenti_servizi) {
          this.getRaggruppamentiServizi().then(this.getServizi());
          break;
        }
        this.getServizi();
        break;
      case "aree":
        if (this.visible.raggruppamenti_aree) {
          this.getRaggruppamentiAree().then(this.getAree());
          break;
        }
        this.getAree();
        break;
    }
  },
  methods: {
    ...mapMutations({
      setServizio: "Entry/SET_SERVIZIO",
      setArea: "Entry/SET_AREA",
    }),
    pushToCalendar() {
      this.$router.push({
        name: this.$routes.SERVIZI_BREVI.CALENDARIO,
        params: {
          servizio: this.entry.servizio.id,
          area: this.entry.area.id,
        },
      });
    },
    getServizi(first = false) {
      this.loading.servizi = true;
      return new Promise((resolve, reject) => {
        let url = "/api/servizi/" + this.cliente.id + "?brevi=true";
        if (first) {
          url += "&first=true";
        }
        this.$http
          .get(url)
          .then((res) => {
            this.data.servizi = res.data.servizi;
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.loading.servizi = false;
          });
      });
    },
    getRaggruppamentiServizi() {
      this.loading.raggruppamentiServizi = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get(
            "/api/servizi/raggruppamenti_servizi/" +
              this.cliente.id +
              "?brevi=true",
            {
              entry: this.entry,
            }
          )
          .then((res) => {
            this.data.raggruppamentiServizi = res.data.raggruppamenti_servizi;
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.loading.raggruppamentiServizi = false;
          });
      });
    },
    getRaggruppamentiAree() {
      this.loading.raggruppamentiAree = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get(
            "/api/servizi/raggruppamenti_area/" +
              this.cliente.id +
              "?brevi=true",
            {
              entry: this.entry,
            }
          )
          .then((res) => {
            this.data.raggruppamentiAree = res.data.raggruppamenti_area;
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.loading.raggruppamentiAree = false;
          });
      });
    },
    getAree(first = false) {
      this.loading.aree = true;
      return new Promise((resolve, reject) => {
        let url = "/api/aree/" + this.cliente.id + "?brevi=true";
        if (first) {
          url += "&first=true";
        }
        this.$http
          .get(url)
          .then((res) => {
            this.data.aree = res.data.aree;
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.loading.aree = false;
          });
      });
    },
    //funzioni per gestire i dati entry
    selectRaggServizi(value) {
      this.entry.raggruppamento_servizi = value[0];
    },
    selectServizio(value) {
      this.entry.servizio = value[0];
      this.setServizio(value[0]);
    },
    selectRaggAree(value) {
      this.entry.raggruppamento_aree = value[0];
    },
    selectArea(value) {
      this.entry.area = value[0];
      this.setArea(value[0]);
    },
    clear(type) {
      switch (type) {
        case "raggruppamento_servizi":
          this.entry.raggruppamento_servizi = null;
          this.entry.servizio = null;
          this.setServizio({});
          break;
        case "servizio":
          this.entry.servizio = null;
          this.setServizio({});
          if (this.orders.servizio < this.orders.area) {
            this.clear("raggruppamento_aree");
            this.data.aree = null;
          }
          break;
        case "raggruppamento_aree":
          this.entry.raggruppamento_aree = null;
          this.entry.area = null;
          this.setArea({});
          break;
        case "area":
          this.entry.area = null;
          this.setArea({});
          if (this.orders.area < this.orders.servizi) {
            this.clear("raggruppamento_servizi");
            this.data.servizi = null;
          }
          break;
      }
    },
    clearSelect(type) {
      switch (type) {
        case "servizio":
          this.data.servizi = null;
          this.clear("servizio");
          if (this.visible.raggruppamenti_servizi) {
            this.clear("raggruppamento_servizi");
            this.data.raggruppamentiServizi = null;
          }
          break;
        case "area":
          this.data.aree = null;
          this.clear("area");
          if (this.visible.raggruppamenti_aree) {
            this.clear("raggruppamento_aree");
            this.data.raggruppamentiAree = null;
          }
          break;
      }
    },
  },
};
</script>

<style scoped>
.service-description {
  margin: auto;
  border: 1px solid #666;
  padding: 1rem 2rem;
  border-radius: 16px;
  font-size: 0.9rem;
  background-color: #c7defe;
}
</style>