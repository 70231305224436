import Vue from "vue";
import Vuex from "vuex";

import Entry from "./Entry";
import User from "./User";
import Ente from "./Ente";
import Utils from "./Utils";
import Language from "./Language";
import Manage from "./Manage";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Entry,
        User,
        Ente,
        Utils,
        Language,
        Manage
    }
});
