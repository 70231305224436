import http from "../../services/Http";
import store from "../../store/index";
const $http = http();
export default async function requiresManage({ to, next }) {
    if (to.matched.some(record => record.meta.requiresManage)) {
        const chiave = to.query.primaria || to.query.chiave;


        const resServizioId = await $http.get(`/api/entry/${to.params.codice}/info?chiave=${chiave}`)
        const id = resServizioId.data;
        const resForm = await $http.get(`/api/cliente/${to.params.ente}/formData/${id}`);
        const formFields = resForm.data.map(e => {
            if (e.tipo == "select") {
                return {
                    ...e,
                    valori: e.valori
                };
            }
            return e;
        });

        store.commit("Utils/SET_FORM", formFields);
        store.dispatch("Manage/fetchManage", {
            codice: to.params.codice,
            chiave: chiave
        })
            .then(() => {
                next();
            })
            .catch((error) => { throw error });
    }

}
