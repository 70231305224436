var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cliente && _vm.$route.matched.some(function (ref) {
    var name = ref.name;

    return name != 'Home';
}))?_c('div',{staticClass:"it-nav-wrapper"},[_c('div',{staticClass:"it-header-center-wrapper d-none d-md-block",style:({
      backgroundColor: 'white',
    })},[_c('div',{staticClass:"container h-100 d-flex align-items-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"it-header-center-content-wrapper p-0"},[_c('div',{staticClass:"it-brand-wrapper"},[_c('router-link',{staticClass:"d-flex justify-content-around w-100",attrs:{"to":{
                  name: 'HomeCliente',
                  params: _vm.cliente.Codice,
                }}},[_c('img',{staticClass:"img-fluid mr-3",staticStyle:{"max-width":"300px"},attrs:{"src":_vm.logo,"alt":_vm.$t('cliente.nome') == 'cliente.nome' ? _vm.cliente.Nome :
                _vm.$t('cliente.nome')}})])],1),_c('div',{staticClass:"it-right-zone"})])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }