<template>
  <div
    v-on:keyup.enter="$router.push(to)"
    tabindex="0"
    :aria-describedby="cardName + '_description'"
    :aria-labelledby="cardName + '_title'"
    >
    <component
      :is="type"
      v-if="size != 0"
      :to="type == 'router-link' ? to : null"
      :href="type == 'a' ? '//' + to : null"
      :target="type == 'a' ? '_blank' : null"
      style="text-decoration: none; color: inherit; cursor: pointer"
    >
      <div class="card-custom--wrapper custom-card-bg">
        <div
          class="card-custom--title"
          :style="styleObject"
          :id="cardName + '_title'"
        >
          <slot name="title"> </slot>
        </div>
        <div class="card-custom--body" v-if="size != 'xs'">
          <div
            :id="cardName + '_description'"
            class="card-custom--body-description text-sans-serif"
            v-if="slots.description"
          >
            <slot name="description"> </slot>
          </div>

        <div class="card-custom--icon" v-if="slots.icon && size != 'sm'">
          <slot name="icon"> </slot>
        </div>
      </div>
      </div>
      </component>
  </div>
</template>

<script>
import { colors } from "../mixins/colors";
/**
 * @vuese
 * Card home page
 */
export default {
  mixins: [colors],
  name: "Card",
  props: {
    link: {
      type: [String, Object],
    },
    externalLink: {
      type: String,
    },
    color: {
      required: false,
    },
    size: {
      type: String,
      default: "md",
      required: false,
    },
    cardName: {
      required: true,
      type: String,
    },
  },
  computed: {
    to() {
      if (this.link) {
        if (typeof this.link === 'string') {
          return {
            name: this.link,
            params: { ente: this.$route.params.ente },
          };
        }
        else {
          return this.link
        }
      }
      return this.externalLink

    },
    type() {
      if (this.link) return 'router-link';
      return 'a';
    },
    styleObject() {
      let  backgroundColor = "";
      if(this.color) {
        if(this.color.match( /[0-9A-Fa-f]{6}/g)) backgroundColor = this.color;
        else backgroundColor = this.colors[this.color];
      }else if(this.$store.state.Ente.colors && process.env.VUE_APP_CARD_COLOR){
        backgroundColor = this.$store.state.Ente.colors.sfondo[0];
      }

      const color = this.getContrastText(backgroundColor);
      return {
        backgroundColor,
        color,
        height: this.size == 'xs' ? '100%' : null
      };
    },
    slots() {
      return {
        title: !!this.$slots.title,
        description: !!this.$slots.description,
        icon: !!this.$slots.icon,
      };
    },
    // sizeStyle() {
    //   let height = "100%";
    //   if (!this.slots.description && !this.slots.icon)
    //     return { height: "0px", padding: 0 };
    //   switch (this.size) {
    //     case "xs" :
    //       height = 0;
    //       break;
    //     case "sm":
    //       height = "100px";
    //       break;
    //     case "md":
    //       height = "50%";
    //       break;
    //     case "lg":
    //       height = "100%";
    //       break;
    //   }
    //   return {
    //     height,
    //   };
    // },
  },
};
</script>

<style scoped>
.card-custom--wrapper {
  width: 100%;
}

.card-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card-bg {
  background-color: white;
  border: 1px solid #505a5e;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.card-custom--title {
  background: #505a5e;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-bottom: 1px solid black;
}

.card-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-custom--body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-custom--icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-custom--icon img {
  max-width: 300px;
  max-height: 100%;
}

.card-custom--body-description {
  max-height: 30%;
}
</style>