<template>
  <div
    class="it-nav-wrapper"
    v-if="cliente && $route.matched.some(({ name }) => name != 'Home')"
  >
    <div
      class="it-header-center-wrapper d-none d-md-block"
      :style="{
        backgroundColor: 'white',
      }"
    >
      <div class="container h-100 d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <div class="it-header-center-content-wrapper p-0">
              <div class="it-brand-wrapper">
                <router-link
                  :to="{
                    name: 'HomeCliente',
                    params: cliente.Codice,
                  }"
                  class="d-flex justify-content-around w-100"
                >
                  <img class="img-fluid mr-3" :src="logo" :alt="
                  $t('cliente.nome') == 'cliente.nome' ? cliente.Nome :
                  $t('cliente.nome')" style="max-width: 300px"/>

                </router-link>
              </div>
              <div class="it-right-zone">
                <!--cose a destra della navabar -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @vuese
 * @group Navbar
 * Header Navbar con logo e nome del cliente
 */
export default {
  name: "NavBarHeader",
  computed: {
    logo() {
      if (this.cliente.Logo) {
        return `${process.env.VUE_APP_SERVER_URL}/storage/${this.cliente.Logo}`;
      }
      return `${process.env.VUE_APP_SERVER_URL}/storage/headerLogoGeneral.jpg`;
    },
  },
};
</script>

<style>
</style>
