<template>
  <div>
    <div class="row mt-5">
      <!-- CHI -->
      <div class="col-md-6 col-12 px-3">
        <!-- <img
          src="../assets/Logo.gif"
          alt=""
          class="img-fluid mx-auto"
          width="100"
        /> -->
        <h1>EasyStaff</h1>
        {{ $t("welcome.message") }}
      </div>
      <div class="col-md-6 col-12 p-md-5 pt-5 px-3">
        <h2 class="text-capitalize">{{ $t("welcome.benvenuto") }}</h2>
        <div v-if="loading" class="mt-3">
          <select-loading style="width: 100%" />
        </div>
        <div v-if="clienti" class="mt-3">
          <custom-select
            :placeholder="$t('welcome.select')"
            :object="clienti"
            description="Nome"
            :id="'id'"
            name="select cliente"
            @selected="selectEnte"
          ></custom-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectLoading from "../components/loading/SelectLoading";
import CustomSelect from "../components/inputs/CustomSelect";
//@vuese
// @group Views
//Pagina iniziale di welcome
export default {
  name: "Home",
  components: {
    SelectLoading,
    CustomSelect,
  },
  data() {
    return {
      clienti: null,
      loading: true,
    };
  },
  //@vuese
  //Carica clienti quando la pagina viene caricata
  mounted() {
    this.getClienti();
  },
  methods: {
    //@vuese
    //Funzione asincrona chiama api/cliente/index
    async getClienti() {
      const res = await this.$http.get("/api/cliente/index");

      this.clienti = res.data;
      this.loading = false;
    },
    //@vuese
    //Prende il cliente dall'evento e sposta la navigazione nella HomeCliente
    // @arg event[Cliente]
    selectEnte: function (event) {
      this.$router.push({
        name: "HomeCliente",
        params: { ente: event[0].Codice },
      });
    },
  },
};
</script>
