<template>
    <div class="row my-5">
        <div class="col-md-6 col-12">
            <h4>
                {{ $t('qr_code.title') }}
            </h4>
            <p>{{ $t('qr_code.description') }}
            </p>
            <p v-if="error" class="text-danger">{{ $t('qr_code.invalid') }}</p>
        </div>
        <div class="col-md-6 col-12 mb-5">
            <qrcode-stream @decode="onDecode" :track="paintOutline" @init="onInit">
                <loading-spinner v-if="loading"></loading-spinner>

            </qrcode-stream>

        </div>

    </div>
</template>
  
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    name: "QrScan",

    data() {
        return {
            error: false,
            loading: false,

        }
    },
    components: {
        QrcodeStream,
    },
    methods: {
        async onInit(promise) {
            this.loading = true

            try {
                await promise
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        onDecode(decodedQr) {
            console.log(!decodedQr.includes(process.env.VUE_APP_SERVER_URL))
            if (!decodedQr.includes(process.env.VUE_APP_SERVER_URL)) return this.error = true;
            window.location = decodedQr
        },
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();

                const { boundingBox } = detectedCode

                const centerX = boundingBox.x + boundingBox.width / 2
                const centerY = boundingBox.y + boundingBox.height / 2


                ctx.font = `bold 100px sans-serif`
                ctx.textAlign = "center"

                ctx.lineWidth = 3
                ctx.strokeStyle = '#00FF00'
                ctx.strokeText('✔', centerX, centerY)


            }
        },
    }


};
</script>
  
<style scoped>

</style>