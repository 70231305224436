import {Cookie, store,parseJwt} from '../services'

import Home from "../../views/Home.vue";
import HomeCliente from "../../views/HomeCliente.vue";

export default [
    {
        path: "/",
        redirect: { name: "Home" },
        name : 'redirect'
    },

    
    {
        path: "/welcome",
        name: "Home",
        meta: { external: true },
        component: Home,
        beforeEnter(_, from, next) {
            if (Cookie.get("user")) {
                if (from.name == null) {
                    if (localStorage.getItem("redirect")) {
                        store.commit(
                            "Utils/SET_SESSION",
                            JSON.parse(sessionStorage.getItem("state"))
                        );
                        sessionStorage.removeItem("state");
                        const path = localStorage.getItem("redirect");
                        localStorage.removeItem("redirect");

                        next(path);
                    }
                }

                next();
            }

            next();
        }
    },

    
    {
        path: "/:ente",
        name: "HomeCliente",
        component: HomeCliente,
        meta: { translateBasedOnCliente: true },
        beforeEnter(to, _, next) {
            if (Cookie.get("user")) {
                const userAttributes = parseJwt(Cookie.get("user"));
                store.commit("User/SET_IS_LOGGED");
                store.commit("User/SET_LOGGEDJWT", userAttributes);

                store.dispatch("Ente/fetchCliente", to);

                next();
            }
            next();
        }
    },
    
]