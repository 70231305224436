import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js";
import $ from 'jquery';
import date_picker from "./date_picker.vue";

const DatePicker = {
    install(Vue) {
        Vue.prototype.$ = $;
        Vue.component("date-picker", date_picker);
    }
};
export default DatePicker;
