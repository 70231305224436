const Gestisci = () =>
    import(
        /* webpackChunkName: "gestisci-find" */ "../../views/Gestisci/Find.vue"
    );
const Show = () =>
    import(
        /* webpackChunkName: "gestisci-show" */ "../../views/Gestisci/Show.vue"
    );
const Edit = () =>
    import(
        /* webpackChunkName: "gestisci-edit" */ "../../views/Gestisci/Edit.vue"
    );
const MyBookings = () =>
    /* webpackChunkName: "gestisci-myBooking" */ "../../views/MyBookings.vue";
export default [{
    path: "/:ente/gestisci",
    name: "Gestisci",
    component: Gestisci,
    meta: { translateBasedOnCliente: true, keepAlive: true }
},
{
    path: "/:ente/gestisci/show/:codice",
    name: "Show",
    component: Show,
    meta: { requiresManage: true, translateBasedOnCliente: true }
},
{
    path: "/:ente/gestisci/show/:codice/modifica",
    name: "Edit",
    component: Edit,
    meta: { requiresManage: true, translateBasedOnCliente: true }
},
{
    path: "/:ente/MyBookings",
    name: "MyBookings",
    component: MyBookings,
    meta: { translateBasedOnCliente: true }
},
]