import Vue from "vue";
import App from "./App.vue";
import wb from "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { i18n } from "./i18n";
import axios from "axios";
import Vuelidate from "vuelidate";
import Vue2TouchEvents from "vue2-touch-events";


import Plugin from './plugin'
Vue.use(Plugin)
//tooltip
import FloatingVue from 'floating-vue'
Vue.use(FloatingVue, {
    defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
})
import 'floating-vue/dist/style.css'
import VueStarRating from 'vue-star-rating'
Vue.component('star-rating', VueStarRating);


Vue.use(Vue2TouchEvents);
// Vue.use(VueTour)

Vue.use(Vuelidate);

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] =
    "Bearer " + process.env.VUE_APP_API_KEY;

//parte alla ricezione di ogni risposta
//qui mi interessa il caso in cui ci sia un errore

Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;

const loadTranslationsFromApi = async () => {
    try {
        const response = await fetch(process.env.VUE_APP_SERVER_URL + "/api/locales");
        const translations = await response.json();

        for (const locale in translations) {
            i18n.setLocaleMessage(locale, translations[locale]);
        }
    } catch (error) {
        console.error('Errore nel caricamento delle traduzioni dall\'API', error);
    }

    new Vue({
        render: h => h(App),
        router,
        store,
        i18n,
    }).$mount('#app');
};

loadTranslationsFromApi();
