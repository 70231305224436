var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropDownMenu),expression:"closeDropDownMenu"}],staticClass:"navbar navbar-expand-lg ",style:(_vm.styleObject),attrs:{"data-tour-step":"1","data-tour-step-2":"4","id":"navbar-sm"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar--title text-truncate"},[(_vm.cliente)?_c('router-link',{staticClass:"navbar-brand ",attrs:{"to":_vm.to}},[_vm._v(_vm._s(_vm.$t("cliente.nome") == "cliente.nome" ? _vm.cliente.Nome : _vm.$t("cliente.nome")))]):_c('router-link',{staticClass:"navbar-brand",attrs:{"to":_vm.to}},[_vm._v("EasyStaff")])],1),(_vm.user)?_c('span',{staticClass:"nav-item d-flex d-lg-none"},[_c('img',{staticClass:"icon-user rounded-circle",staticStyle:{"width":"35px"},attrs:{"alt":"imagine avatar","src":'https://api.dicebear.com/7.x/initials/svg/seed=' +
                        _vm.user['cognome_nome']}})]):_vm._e(),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":function($event){_vm.dropDownMenu = !_vm.dropDownMenu},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.dropDownMenu = !_vm.dropDownMenu}}},[_c('i',{staticClass:"fas fa-bars text-white"})]),_c('div',{staticClass:"collapse navbar-collapse d-none megamenu",class:{ 'd-block': _vm.dropDownMenu },attrs:{"id":"navbarDropDown"}},[_c('ul',{staticClass:"navbar-nav ml-auto position-relative"},[_c('div',{staticClass:"d-md-none"},[_c('li',{staticClass:"nav-item dropdown mr-3"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact-active-class":"font-weight-bold","to":{ name: 'HomeCliente' }}},[_vm._v(_vm._s(_vm.$t("menu.home-link")))])],1),_c('li',{staticClass:"nav-item dropdown mr-3"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact-active-class":"font-weight-bold","to":{ name: 'servizi' }}},[_vm._v(_vm._s(_vm.$t("menu.new-link")))])],1),(
                            _vm.configs &&
                                _vm.configs.general &&
                                _vm.configs.general.servizi_brevi &&
                                _vm.configs.general.servizi_brevi != '0'
                        )?_c('li',{staticClass:"nav-item dropdown mr-3"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact-active-class":"font-weight-bold","to":{ name: _vm.$routes.SERVIZI_BREVI.SERVIZI }}},[_vm._v(_vm._s(_vm.$t("servizi_brevi.title")))])],1):_vm._e(),_c('li',{staticClass:"nav-item dropdown mr-3"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact-active-class":"font-weight-bold","to":{ name: 'Gestisci' }}},[_vm._v(_vm._s(_vm.$t("menu.manage-link")))])],1),(
                            _vm.configs &&
                                _vm.configs.portale_pubblico &&
                                _vm.configs.portale_pubblico.scansiona_qr != 0
                        )?_c('li',{staticClass:"nav-item dropdown mr-3"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact-active-class":"font-weight-bold","to":{
                                name: _vm.$routes.QRSCAN
                            }}},[_c('span',[_vm._v(_vm._s(_vm.$t("qr_code.card_title")))])])],1):_vm._e(),(
                            _vm.configs.portale_pubblico &&
                                _vm.configs.portale_pubblico.link_agenda
                        )?_c('li',{staticClass:"nav-item dropdown mr-3"},[_c('a',{staticClass:"nav-link",attrs:{"href":_vm.configs.portale_pubblico.link_agenda}},[_c('span',[_vm._v(_vm._s(_vm.$t("menu.link_agenda")))])])]):_vm._e()]),(_vm.cliente)?_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenuDesktop),expression:"closeMenuDesktop"}],staticClass:"nav-item dropdown megamenu d-none d-md-block",class:{ show: _vm.dropMenuDesktop }},[_c('div'),_c('a',{staticClass:"nav-link dropdown-toggle",class:_vm.dropMenuDesktop ? 'open' : 'closed',attrs:{"aria-expanded":_vm.dropMenuDesktop,"data-toggle":"dropdown","tabindex":"0","aria-labelledby":"menu_label"},on:{"click":function($event){_vm.dropMenuDesktop = !_vm.dropMenuDesktop},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.dropMenuDesktop = !_vm.dropMenuDesktop}}},[_c('span',{attrs:{"id":"menu_label"}},[_vm._v("MENU")]),_c('i',{staticClass:"fas fa-chevron-down ml-2",class:{
                                'fa-chevron-down': !_vm.dropMenuDesktop,
                                ' fa-chevron-up': _vm.dropMenuDesktop
                            }})]),_c('div',{staticClass:"dropdown-menu-custom",class:{ 'show-dropdown': _vm.dropMenuDesktop },staticStyle:{"width":"250px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"link-list-wrapper"},[_c('ul',{staticClass:"link-list text-black"},[_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"exact-active-class":"font-weight-bold","to":{
                                                    name: 'HomeCliente'
                                                }}},[_c('span',[_vm._v(_vm._s(_vm.$t("menu.home-link")))])])],1),_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"exact-active-class":"font-weight-bold","to":{ name: 'servizi' }}},[_c('span',[_vm._v(_vm._s(_vm.$t("menu.new-link")))])])],1),(
                                                _vm.configs &&
                                                    _vm.configs.general &&
                                                    _vm.configs.general
                                                        .servizi_brevi &&
                                                    _vm.configs.general
                                                        .servizi_brevi !=
                                                        '0'
                                            )?_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"exact-active-class":"font-weight-bold","to":{
                                                    name:
                                                        _vm.$routes
                                                            .SERVIZI_BREVI
                                                            .SERVIZI
                                                }}},[_c('span',[_vm._v(_vm._s(_vm.$t( "servizi_brevi.title" )))])])],1):_vm._e(),_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"exact-active-class":"font-weight-bold","to":{ name: 'Gestisci' }}},[_c('span',[_vm._v(_vm._s(_vm.$t("menu.manage-link")))])])],1),(
                                                _vm.configs &&
                                                    _vm.configs.portale_pubblico &&
                                                    _vm.configs.portale_pubblico
                                                        .scansiona_qr != 0
                                            )?_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"exact-active-class":"font-weight-bold","to":{
                                                    name: _vm.$routes.QRSCAN
                                                }}},[_c('span',[_vm._v(_vm._s(_vm.$t("qr_code.card_title")))])])],1):_vm._e(),(
                                                _vm.configs.portale_pubblico &&
                                                    _vm.configs.portale_pubblico
                                                        .link_agenda
                                            )?_c('li',{staticClass:"list-item"},[_c('a',{attrs:{"href":_vm.configs.portale_pubblico
                                                        .link_agenda}},[_c('span',[_vm._v(_vm._s(_vm.$t("menu.link_agenda")))])])]):_vm._e()])])])])])]):_vm._e(),_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeLengMenu),expression:"closeLengMenu"}],staticClass:"nav-item dropdown mr-3",attrs:{"tabindex":"0"},on:{"click":_vm.drop,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.drop($event)}}},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","aria-expanded":_vm.dropDown,"aria-labelledby":"active_lang"}},[_c('span',{staticClass:"d-md-none"},[_vm._v("Lingua : ")]),_c('span',{attrs:{"id":"active_lang"}},[_vm._v(_vm._s(_vm.lang.toUpperCase()))]),_c('i',{staticClass:"fas fa-chevron-down ml-2",class:{
                                'fa-chevron-down': !_vm.dropDown,
                                ' fa-chevron-up': _vm.dropDown
                            }})]),_c('div',{staticClass:"dropdown-menu-custom",class:{ 'show-dropdown': _vm.dropDown },attrs:{"aria-expanded":_vm.dropDown}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"link-list-wrapper"},[_c('ul',{staticClass:"link-list"},_vm._l((_vm.locales),function(locale){return _c('li',{key:locale},[_c('a',{staticClass:"list-item pointer",class:{
                                                    'font-weight-bold':
                                                        _vm.lang ==
                                                        locale.toLowerCase()
                                                },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.changeLanguage(locale)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changeLanguage(locale)}}},[_c('span',[_vm._v(_vm._s(locale.toUpperCase()))])])])}),0)])])])])])]),(_vm.user)?_c('span',{staticClass:"nav-item d-lg-flex d-none"},[_c('img',{staticClass:"icon-user rounded-circle",staticStyle:{"width":"35px"},attrs:{"alt":"immagine avatar","src":'https://api.dicebear.com/7.x/initials/svg/seed=' +
                            _vm.user['cognome_nome']}})]):_vm._e(),_c('div',{staticClass:"navbar--login-desktop d-none d-md-block"},[(!_vm.isLogged && _vm.loginAbilitato)?_c('div',{staticClass:"it-access-top-wrapper"},[_c('a',{on:{"click":_vm.login,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}}},[(_vm.cliente)?_c('e-button',{staticClass:"btn-sm nav-link",attrs:{"intensity":200,"dontDisable":true,"data-cypress":"navBar-Accedi"}},[_vm._v(_vm._s(_vm.$t("menu.login")))]):_vm._e()],1)]):(
                        _vm.isLogged && _vm.loginAbilitato && !_vm.hideLogoutButton
                    )?_c('div',{staticClass:"it-access-top-wrapper nav-link"},[_c('e-button',{staticClass:"btn-sm nav-link",attrs:{"intensity":200,"outlined":""},on:{"click":_vm.logoutModal,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logoutModal($event)}}},[_vm._v(_vm._s(_vm.$t("menu.logout")))])],1):_vm._e()]),_c('div',{staticClass:"navbar--login-mobile d-md-none mt-2"},[(!_vm.isLogged && _vm.loginAbilitato)?_c('div',{staticClass:"it-access-top-wrapper"},[_c('a',{on:{"click":_vm.login,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}}},[_c('e-button',{staticClass:"btn-lg w-100",attrs:{"intensity":200,"dontDisable":true}},[_vm._v(_vm._s(_vm.$t("menu.login")))])],1)]):(
                        _vm.isLogged && _vm.loginAbilitato && !_vm.hideLogoutButton
                    )?_c('div',{staticClass:"it-access-top-wrapper"},[_c('e-button',{staticClass:"btn-lg w-100",attrs:{"intensity":200,"outlined":""},on:{"click":_vm.logoutModal,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logoutModal($event)}}},[_vm._v(_vm._s(_vm.$t("menu.logout")))])],1):_vm._e(),_c('p',{staticClass:"text-center w-100 text-white mt-3 test-small"},[_vm._v(" Powered by EasyStaff - "+_vm._s(new Date().getFullYear())+" - "+_vm._s(_vm.version.replace(/"/g, ""))+" ")])])])]),(_vm.showLogoutModal)?_c('modal',{on:{"close":function($event){_vm.showLogoutModal = false}}},[_c('template',{slot:"body"},[_vm._v(_vm._s(_vm.$t("menu.logout_modale_message")))]),_c('template',{slot:"footer"},[_c('e-button',{on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t("buttons.confirm")))]),_c('e-button',{attrs:{"outlined":""},on:{"click":function($event){_vm.showLogoutModal = false}}},[_vm._v(_vm._s(_vm.$t("buttons.annulla")))])],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }