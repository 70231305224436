import Auth from './Auth'
import Gestisci from './Gestisci'
import Home from './Home'
import Prenota from './Prenota'
import ServiziBrevi from './ServiziBrevi'
import Statistiche from './Statistiche'
const NotFound = () => /* webpackChunkName: "not-found" */ "../../views/NotFound";
import Error from "../../views/Error.vue";
import QrScan from '../../views/QrScan';

export const routes = [
    ...Home, ...Auth, ...Gestisci, ...Prenota, ...ServiziBrevi, ...Statistiche,
    {
        path: '/:ente/validate_qr',
        name: 'QrScan',
        component: QrScan
    },
    {
        path: "/:ente/404",
        name: "NotFound",
        component: NotFound
    },
    {
        path: "/error",
        name: "Error",
        component: Error
    },
]

export const routesNames = Object.assign({}, ...routes.map((e) => {
    if (e.children) return {
        [e.name.toUpperCase()]:
            Object.assign({},
                ...e.children.map((c) => {
                    let parentName = c.name.split('.').length > 1 ? c.name.split('.')[1].toUpperCase() : c.name
                    return { [parentName.toUpperCase()]: c.name }
                })
            )
    };
    return { [e.name.toUpperCase()]: e.name }
}))