var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-5"},[_c('div',{class:{ error: _vm.$v[_vm.input.codice].$error }},[(_vm.input.tipo == 'select')?_c('select',{staticClass:"form-select w-full",attrs:{"aria-label":"select"},on:{"change":function($event){_vm.$v[_vm.input.codice].$model = $event.target.value}}},[_c('option',{attrs:{"value":"1","disabled":""},domProps:{"selected":!_vm.$v[_vm.input.codice].$model}},[_vm._v(" "+_vm._s(_vm.input.label)),(_vm.input.obbligatorio)?_c('span',{staticClass:"asterisco"},[_vm._v(" *")]):_vm._e()]),_vm._l((_vm.input.valori),function(option,key){return _c('option',{key:option,domProps:{"selected":key === _vm.$v[_vm.input.codice].$model ? true : false,"value":key}},[_vm._v(" "+_vm._s(option)+" ")])})],2):(_vm.input.tipo == 'history_select')?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('form.history_select')),expression:"$t('form.history_select')"}]},[_c('Select2',{attrs:{"options":_vm.input.valori,"settings":{ tags: true },"placeholder":_vm.input.label + (_vm.input.obbligatorio ? ' *' : '')},on:{"change":function($event){_vm.$v[_vm.input.codice].$model = $event}}})],1):(_vm.input.tipo == 'tel')?_c('div',[_c('div',[_c('vue-phone-number-input',{style:(_vm.$v[_vm.input.codice].$error
                            ? { border: '1px solid red' }
                            : ''),attrs:{"translations":_vm.phoneLabels,"disabled":_vm.disabled,"size":"lg","error-color":"red","id":_vm.id ? _vm.id : _vm.input.id.toString()},model:{value:(_vm.$data[_vm.input.codice]),callback:function ($$v) {_vm.$set(_vm.$data, _vm.input.codice, $$v)},expression:"$data[input.codice]"}})],1)]):(_vm.input.tipo == 'date' || _vm.input.tipo == 'time')?_c('div',[_c('label',{attrs:{"for":_vm.id ? _vm.id : _vm.input.id}},[_vm._v(" "+_vm._s(_vm.$t(("form.campi." + (_vm.input.codice) + " ")) == ("form.campi." + (_vm.input.codice) + " ") ? _vm.input.label : _vm.$t(("form.campi." + (_vm.input.codice) + " ")))+" "),(_vm.input.obbligatorio)?_c('span',{staticClass:"asterisco"},[_vm._v("*")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v[_vm.input.codice].$model),expression:"$v[input.codice].$model",modifiers:{"lazy":true}}],class:{
                    valid: _vm.$v[_vm.input.codice].$dirty,
                    invalid: _vm.$v[_vm.input.codice].$error
                },style:(_vm.$v[_vm.input.codice].$error
                        ? { border: '1px solid red' }
                        : ''),attrs:{"aria-required":_vm.input.obbligatorio ? 'true' : 'false',"id":_vm.id ? _vm.id : _vm.input.id.toString(),"disabled":_vm.disabled,"type":_vm.input.tipo,"placeholder":"gg/MM/aaaa"},domProps:{"value":(_vm.$v[_vm.input.codice].$model)},on:{"change":function($event){return _vm.$set(_vm.$v[_vm.input.codice], "$model", $event.target.value)}}})]):(_vm.input.tipo == 'checkbox')?_c('div',{staticClass:"form-check "},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v[_vm.input.codice].$model),expression:"$v[input.codice].$model",modifiers:{"lazy":true}}],staticClass:"mr-2",class:{
                    valid: !_vm.$v[_vm.input.codice].$invalid,
                    invalid: _vm.$v[_vm.input.codice].$error
                },style:(_vm.$v[_vm.input.codice].$error
                        ? { border: '1px solid red' }
                        : ''),attrs:{"aria-required":_vm.input.obbligatorio ? 'true' : 'false',"id":_vm.id ? _vm.id : _vm.input.id.toString(),"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v[_vm.input.codice].$model)?_vm._i(_vm.$v[_vm.input.codice].$model,null)>-1:(_vm.$v[_vm.input.codice].$model)},on:{"change":[function($event){var $$a=_vm.$v[_vm.input.codice].$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v[_vm.input.codice], "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v[_vm.input.codice], "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v[_vm.input.codice], "$model", $$c)}},function($event){_vm.$v[_vm.input.codice].$touch()}]}}),(_vm.input.codice == 'gdpr')?_c('label',{staticClass:"m-0",attrs:{"for":_vm.id ? _vm.id : _vm.input.id}},[_vm._v(" "+_vm._s(_vm.$t("form.campi.gdpr.text"))+" "),_c('a',{attrs:{"href":_vm.getGdprUrl(_vm.input),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("form.campi.gdpr.link")))]),_c('span',{staticClass:"asterisco"},[_vm._v("*")])]):_c('label',{attrs:{"for":_vm.id ? _vm.id : _vm.input.id}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.input.label)}}),(_vm.input.obbligatorio)?_c('span',{staticClass:"asterisco"},[_vm._v("*")]):_vm._e()])]):(_vm.input.tipo == 'textarea')?_c('div',[_c('label',{attrs:{"for":_vm.id ? _vm.id : _vm.input.id}},[_vm._v(_vm._s(_vm.$t(("form.campi." + (_vm.input.codice) + " ")) == ("form.campi." + (_vm.input.codice) + " ") ? _vm.input.label : _vm.$t(("form.campi." + (_vm.input.codice) + " ")))+" "),(_vm.input.obbligatorio)?_c('span',{staticClass:"asterisco"},[_vm._v("*")]):_vm._e()]),_c('textarea',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v[_vm.input.codice].$model),expression:"$v[input.codice].$model",modifiers:{"lazy":true}}],class:{
                    valid: !_vm.$v[_vm.input.codice].$invalid,
                    invalid: _vm.$v[_vm.input.codice].$error
                },staticStyle:{"border-radius":"10px"},style:(_vm.$v[_vm.input.codice].$error
                        ? { border: '1px solid red' }
                        : ''),attrs:{"aria-required":_vm.input.obbligatorio ? 'true' : 'false',"id":_vm.id ? _vm.id : _vm.input.id.toString(),"disabled":_vm.disabled,"type":_vm.input.tipo,"placeholder":(_vm.input.placeholder || _vm.input.label) +
                        (_vm.input.obbligatorio ? '*' : ''),"rows":"4","cols":"100"},domProps:{"value":(_vm.$v[_vm.input.codice].$model)},on:{"change":function($event){return _vm.$set(_vm.$v[_vm.input.codice], "$model", $event.target.value)}}})]):_c('div',{staticClass:"input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v[_vm.input.codice].$model),expression:"$v[input.codice].$model",modifiers:{"lazy":true}}],class:{
                    valid: _vm.$v[_vm.input.codice].$dirty,
                    invalid: _vm.$v[_vm.input.codice].$error
                },style:(_vm.$v[_vm.input.codice].$error
                        ? { border: '1px solid red' }
                        : ''),attrs:{"name":_vm.input.codice,"aria-required":_vm.input.obbligatorio ? 'true' : 'false',"id":_vm.id ? _vm.id : _vm.input.id.toString(),"disabled":_vm.disabled,"type":_vm.input.tipo,"placeholder":_vm.$t(("form.campi." + (_vm.input.codice) + " ")) ==
                    ("form.campi." + (_vm.input.codice) + " ")
                        ? _vm.input.placeholder
                        : _vm.$t(("form.campi." + (_vm.input.codice) + " "))},domProps:{"value":(_vm.$v[_vm.input.codice].$model)},on:{"change":function($event){return _vm.$set(_vm.$v[_vm.input.codice], "$model", $event.target.value)}}}),_c('label',{attrs:{"for":_vm.id ? _vm.id : _vm.input.id}},[_vm._v(_vm._s(_vm.$t(("form.campi." + (_vm.input.codice) + " ")) == ("form.campi." + (_vm.input.codice) + " ") ? _vm.input.label : _vm.$t(("form.campi." + (_vm.input.codice))))+" "),(_vm.input.obbligatorio)?_c('span',{staticClass:"asterisco"},[_vm._v("*")]):_vm._e()])])]),(_vm.$v[_vm.input.codice].$error || _vm.showErrors)?_c('div',{staticClass:"errors"},[(
                ('required' in _vm.$v[_vm.input.codice] &&
                    !_vm.$v[_vm.input.codice].required) ||
                    'sameAs' in _vm.$v[_vm.input.codice]
            )?_c('div',{staticClass:"error"},[_vm._v(" ⓘ "+_vm._s(_vm.$t("errors.required"))+" ")]):_vm._e(),('email' in _vm.$v[_vm.input.codice] && !_vm.$v[_vm.input.codice].email)?_c('div',{staticClass:"error"},[_vm._v(" ⓘ "+_vm._s(_vm.$t("errors.email"))+" ")]):_vm._e(),(
                'codiceFiscale' in _vm.$v[_vm.input.codice] &&
                    !_vm.$v[_vm.input.codice].codiceFiscale
            )?_c('div',{staticClass:"error"},[_vm._v(" ⓘ "+_vm._s(_vm.$t("errors.codice_fiscale"))+" ")]):_vm._e(),(
                'phoneNumeber' in _vm.$v[_vm.input.codice] &&
                    !_vm.$v[_vm.input.codice].phoneNumeber
            )?_c('div',{staticClass:"error"},[_vm._v(" ⓘ "+_vm._s(_vm.$t("errors.phone"))+" ")]):_vm._e(),('gdpr' in _vm.$v[_vm.input.codice] && !_vm.$v[_vm.input.codice].gdpr)?_c('div',{staticClass:"error"},[_vm._v(" ⓘ "+_vm._s(_vm.$t("errors.gdpr"))+" ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }