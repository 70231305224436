import { required, sameAs } from "vuelidate/lib/validators";

const codiceFiscale = value => {
    if (!value) return true;
    if (value.trim() === '') return true;

    var pattern_erasmus = /^ERASMUSINC[0-9]{6}$/;
    if (value.toUpperCase().search(pattern_erasmus) != -1) return true;

    var pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$/;
    // utilizzo il metodo search per verificare che il valore inserito nel campo
    // di input rispetti la stringa di verifica (pattern)
    if (value.search(pattern) == -1) {
        return false;
    }
    return true;
};

const phoneNumeber = () => {
    return true;
}

const gdpr = value => {
    if (value) {
        return true;
    }
    return false;

}

const emailValidation = value => {
    if (!value) return true; // Se il valore è vuoto, consideralo valido
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(value);
};



class ValidationRule {
    constructor(element) {
        this.element = element;
        this.type = element.tipo;
        this.validation = {};
        return this.setRules();

    }
    setRules() {
        if (this.element.obbligatorio == 1) {
            if (this.element.tipo == 'checkbox' && this.element.codice != 'gdpr') {
                this.validation.sameAs = sameAs(() => true);
            }
            this.validation.required = required;
        }
        switch (this.element.codice) {
            case "email":
                this.validation.email = emailValidation;
                break;
            case "codice_fiscale":
                this.validation.codiceFiscale = codiceFiscale;
                break;
            case "phone":
                this.validation.phoneNumeber = phoneNumeber;
                break;
            case 'gdpr':
                this.validation.gdpr = gdpr;
                break;
            case 'password':
                this.validation.required = required;
                break;
        }
        return this.validation;
    }
}

export default ValidationRule;
