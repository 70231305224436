import axios from "axios";
import ColorScheme from "color-scheme";
import { colors } from '../mixins/colors.js';
export default {
    namespaced: true,
    state: {
        cliente: null,
        aree: null,
        colors: null,
        configs: {},
        info: null
    },
    getters: {},
    mutations: {
        SET_CLIENTE_KEY(state, payload) {
            state.cliente[payload.key] = payload.value;
        },
        SET_CLIENTE(state, value) {
            state.cliente = value;
            state.info = {
                nome: value.Nome,
                indirizzo: "test via",
                sito_web: "test sito web"
            };
        },
        SET_CONFIGS(state, value) {
            let obj = {};
            for (const v in value) {
                obj[v] = Object.assign({}, ...value[v]);
            }

            state.configs = obj;
        },
        POPULATE_AREE(state, value) {
            state.aree = value;
        },
        SET_COLORS(state, value) {
            let scheme = new ColorScheme();
            for (const c in value) {
                if (c == "scritte") {
                    const startingColor = value[c];
                    scheme
                        .from_hex(startingColor.split("#")[1])
                        .scheme("tetrade")
                        .variation("hard");
                    const colors = scheme.colors();
                    delete value.sfondo;
                    value.sfondo = {};
                    value.sfondo[0] = startingColor;
                    colors.map(
                        (e, i) => (value.sfondo[i * 100 + 100] = "#" + e)
                    );
                }
            }

            state.colors = value;
            // Creo delle variabili css
            Object.keys(state.colors.sfondo).forEach(key => {
                let color = state.colors.sfondo[key];
                document.documentElement.style.setProperty(
                    `--custom-color-${key}`,
                    color
                );
                document.documentElement.style.setProperty(
                    `--custom-color-rgb-${key}`,
                    hexToRgbA(color)
                );

                const text_color = colors.methods.getContrastText(color);
                document.documentElement.style.setProperty(
                    `--custom-color-text-${key}`,
                    text_color
                );

            });
        }
    },
    actions: {
        fetchCliente({ commit }, to) {
            return new Promise(function(resolve, reject) {
                axios
                    .get(`/api/cliente/show/${to.params.ente}`)
                    .then(res => {
                        let configs = res.data.config;
                        delete res.data.config;
                        commit("SET_CLIENTE", res.data);
                        commit("SET_CONFIGS", configs);
                        commit("SET_COLORS", res.data.colors);
                        resolve(res.data);
                    })
                    .catch(() => reject());
            });
        }
    }
};
function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    }
    throw new Error('Bad Hex');
}

