<template>
    <nav
        class="navbar navbar-expand-lg "
        data-tour-step="1"
        data-tour-step-2="4"
        id="navbar-sm"
        :style="styleObject"
        v-click-outside="closeDropDownMenu"
    >
        <div class="container">
            <div class="navbar--title text-truncate">
                <router-link v-if="cliente" class="navbar-brand " :to="to">{{
                    $t("cliente.nome") == "cliente.nome"
                        ? cliente.Nome
                        : $t("cliente.nome")
                }}</router-link>
                <router-link v-else class="navbar-brand" :to="to"
                    >EasyStaff</router-link
                >
            </div>
            <span class="nav-item d-flex d-lg-none" v-if="user">
                <img
                    alt="imagine avatar"
                    :src="
                        'https://api.dicebear.com/7.x/initials/svg/seed=' +
                            user['cognome_nome']
                    "
                    class="icon-user rounded-circle"
                    style="width: 35px"
                />
            </span>
            <!-- Navbar hamburger -->
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="dropDownMenu = !dropDownMenu"
                @keyup.enter="dropDownMenu = !dropDownMenu"
            >
                <i class="fas fa-bars text-white"></i>
            </button>
            <div
                class="collapse navbar-collapse d-none megamenu"
                id="navbarDropDown"
                :class="{ 'd-block': dropDownMenu }"
            >
                <ul class="navbar-nav ml-auto position-relative">
                    <div class="d-md-none">
                        <li class="nav-item dropdown mr-3">
                            <router-link
                                class="nav-link"
                                exact-active-class="font-weight-bold"
                                :to="{ name: 'HomeCliente' }"
                                >{{ $t("menu.home-link") }}</router-link
                            >
                        </li>
                        <li class="nav-item dropdown mr-3">
                            <router-link
                                class="nav-link"
                                exact-active-class="font-weight-bold"
                                :to="{ name: 'servizi' }"
                                >{{ $t("menu.new-link") }}</router-link
                            >
                        </li>
                        <li
                            class="nav-item dropdown mr-3"
                            v-if="
                                configs &&
                                    configs.general &&
                                    configs.general.servizi_brevi &&
                                    configs.general.servizi_brevi != '0'
                            "
                        >
                            <router-link
                                class="nav-link"
                                exact-active-class="font-weight-bold"
                                :to="{ name: $routes.SERVIZI_BREVI.SERVIZI }"
                                >{{ $t("servizi_brevi.title") }}</router-link
                            >
                        </li>
                        <li class="nav-item dropdown mr-3">
                            <router-link
                                class="nav-link"
                                exact-active-class="font-weight-bold"
                                :to="{ name: 'Gestisci' }"
                                >{{ $t("menu.manage-link") }}</router-link
                            >
                        </li>
                        <li
                            class="nav-item dropdown mr-3"
                            v-if="
                                configs &&
                                    configs.portale_pubblico &&
                                    configs.portale_pubblico.scansiona_qr != 0
                            "
                        >
                            <router-link
                                class="nav-link"
                                exact-active-class="font-weight-bold"
                                :to="{
                                    name: $routes.QRSCAN
                                }"
                                ><span>{{
                                    $t("qr_code.card_title")
                                }}</span></router-link
                            >
                        </li>
                        <li
                            class="nav-item dropdown mr-3"
                            v-if="
                                configs.portale_pubblico &&
                                    configs.portale_pubblico.link_agenda
                            "
                        >
                            <a
                                class="nav-link"
                                :href="configs.portale_pubblico.link_agenda"
                                ><span>{{ $t("menu.link_agenda") }}</span></a
                            >
                        </li>
                    </div>

                    <li
                        class="nav-item dropdown megamenu d-none d-md-block"
                        :class="{ show: dropMenuDesktop }"
                        v-if="cliente"
                        v-click-outside="closeMenuDesktop"
                    >
                        <div></div>
                        <a
                            class="nav-link dropdown-toggle"
                            :class="dropMenuDesktop ? 'open' : 'closed'"
                            :aria-expanded="dropMenuDesktop"
                            data-toggle="dropdown"
                            @click="dropMenuDesktop = !dropMenuDesktop"
                            @keyup.enter="dropMenuDesktop = !dropMenuDesktop"
                            tabindex="0"
                            aria-labelledby="menu_label"
                        >
                            <span id="menu_label">MENU</span>
                            <i
                                class="fas fa-chevron-down ml-2"
                                :class="{
                                    'fa-chevron-down': !dropMenuDesktop,
                                    ' fa-chevron-up': dropMenuDesktop
                                }"
                            ></i>
                        </a>
                        <div
                            class="dropdown-menu-custom"
                            :class="{ 'show-dropdown': dropMenuDesktop }"
                            style="width : 250px"
                        >
                            <div class="row">
                                <div class="col-12">
                                    <div class="link-list-wrapper">
                                        <ul class="link-list text-black">
                                            <li class="list-item">
                                                <router-link
                                                    exact-active-class="font-weight-bold"
                                                    :to="{
                                                        name: 'HomeCliente'
                                                    }"
                                                    ><span>{{
                                                        $t("menu.home-link")
                                                    }}</span></router-link
                                                >
                                            </li>
                                            <li class="list-item">
                                                <router-link
                                                    exact-active-class="font-weight-bold"
                                                    :to="{ name: 'servizi' }"
                                                    ><span>{{
                                                        $t("menu.new-link")
                                                    }}</span></router-link
                                                >
                                            </li>
                                            <li
                                                class="list-item"
                                                v-if="
                                                    configs &&
                                                        configs.general &&
                                                        configs.general
                                                            .servizi_brevi &&
                                                        configs.general
                                                            .servizi_brevi !=
                                                            '0'
                                                "
                                            >
                                                <router-link
                                                    exact-active-class="font-weight-bold"
                                                    :to="{
                                                        name:
                                                            $routes
                                                                .SERVIZI_BREVI
                                                                .SERVIZI
                                                    }"
                                                    ><span>{{
                                                        $t(
                                                            "servizi_brevi.title"
                                                        )
                                                    }}</span></router-link
                                                >
                                            </li>
                                            <li class="list-item">
                                                <router-link
                                                    exact-active-class="font-weight-bold"
                                                    :to="{ name: 'Gestisci' }"
                                                    ><span>{{
                                                        $t("menu.manage-link")
                                                    }}</span></router-link
                                                >
                                            </li>
                                            <li
                                                class="list-item"
                                                v-if="
                                                    configs &&
                                                        configs.portale_pubblico &&
                                                        configs.portale_pubblico
                                                            .scansiona_qr != 0
                                                "
                                            >
                                                <router-link
                                                    exact-active-class="font-weight-bold"
                                                    :to="{
                                                        name: $routes.QRSCAN
                                                    }"
                                                    ><span>{{
                                                        $t("qr_code.card_title")
                                                    }}</span></router-link
                                                >
                                            </li>
                                            <li
                                                class="list-item"
                                                v-if="
                                                    configs.portale_pubblico &&
                                                        configs.portale_pubblico
                                                            .link_agenda
                                                "
                                            >
                                                <a
                                                    :href="
                                                        configs.portale_pubblico
                                                            .link_agenda
                                                    "
                                                    ><span>{{
                                                        $t("menu.link_agenda")
                                                    }}</span></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        class="nav-item dropdown mr-3"
                        @click="drop"
                        @keyup.enter="drop"
                        v-click-outside="closeLengMenu"
                        tabindex="0"
                    >
                        <a
                            class="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                            :aria-expanded="dropDown"
                            aria-labelledby="active_lang"
                        >
                            <span class="d-md-none">Lingua : </span>
                            <span id="active_lang">{{
                                lang.toUpperCase()
                            }}</span>

                            <i
                                class="fas fa-chevron-down ml-2"
                                :class="{
                                    'fa-chevron-down': !dropDown,
                                    ' fa-chevron-up': dropDown
                                }"
                            ></i>
                        </a>
                        <div
                            class="dropdown-menu-custom"
                            :class="{ 'show-dropdown': dropDown }"
                            :aria-expanded="dropDown"
                        >
                            <div class="row">
                                <div class="col-12">
                                    <div class="link-list-wrapper">
                                        <ul class="link-list">
                                            <li
                                                v-for="locale in locales"
                                                :key="locale"
                                            >
                                                <a
                                                    tabindex="0"
                                                    :class="{
                                                        'font-weight-bold':
                                                            lang ==
                                                            locale.toLowerCase()
                                                    }"
                                                    class="list-item pointer"
                                                    @click="
                                                        changeLanguage(locale)
                                                    "
                                                    @keyup.enter="
                                                        changeLanguage(locale)
                                                    "
                                                    ><span>{{
                                                        locale.toUpperCase()
                                                    }}</span></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <span class="nav-item d-lg-flex d-none" v-if="user">
                    <img
                        alt="immagine avatar"
                        :src="
                            'https://api.dicebear.com/7.x/initials/svg/seed=' +
                                user['cognome_nome']
                        "
                        class="icon-user rounded-circle"
                        style="width: 35px"
                    />
                </span>
                <div class="navbar--login-desktop d-none d-md-block">
                    <div
                        v-if="!isLogged && loginAbilitato"
                        class="it-access-top-wrapper"
                    >
                        <a @click="login" @keyup.enter="login">
                            <e-button
                                :intensity="200"
                                :dontDisable="true"
                                class="btn-sm nav-link"
                                data-cypress="navBar-Accedi"
                                v-if="cliente"
                                >{{ $t("menu.login") }}</e-button
                            ></a
                        >
                    </div>
                    <div
                        v-else-if="
                            isLogged && loginAbilitato && !hideLogoutButton
                        "
                        class="it-access-top-wrapper nav-link"
                    >
                        <e-button
                            :intensity="200"
                            class="btn-sm nav-link"
                            @click="logoutModal"
                            @keyup.enter="logoutModal"
                            outlined
                            >{{ $t("menu.logout") }}</e-button
                        >
                    </div>
                </div>
                <div class="navbar--login-mobile d-md-none mt-2">
                    <div
                        v-if="!isLogged && loginAbilitato"
                        class="it-access-top-wrapper"
                    >
                        <a @click="login" @keyup.enter="login">
                            <e-button
                                :intensity="200"
                                :dontDisable="true"
                                class="btn-lg w-100"
                                >{{ $t("menu.login") }}</e-button
                            >
                        </a>
                    </div>
                    <div
                        v-else-if="
                            isLogged && loginAbilitato && !hideLogoutButton
                        "
                        class="it-access-top-wrapper"
                    >
                        <e-button
                            :intensity="200"
                            class="btn-lg w-100"
                            outlined
                            @click="logoutModal"
                            @keyup.enter="logoutModal"
                            >{{ $t("menu.logout") }}</e-button
                        >
                    </div>
                    <p class="text-center w-100 text-white mt-3 test-small">
                        Powered by EasyStaff - {{ new Date().getFullYear() }} -
                        {{ version.replace(/"/g, "") }}
                    </p>
                </div>
            </div>
        </div>
        <modal v-if="showLogoutModal" @close="showLogoutModal = false">
            <template slot="body">{{
                $t("menu.logout_modale_message")
            }}</template>
            <template slot="footer">
                <e-button @click="logout">{{ $t("buttons.confirm") }}</e-button>
                <e-button outlined @click="showLogoutModal = false">{{
                    $t("buttons.annulla")
                }}</e-button>
            </template>
        </modal>
    </nav>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Modal from "../../Modal.vue";
import Cookies from "js-cookie";

/**
 * @vuese
 * @group Navbar
 * Navbar vera e propria
 */
export default {
    components: { Modal },
    name: "NavBarSmall",
    data() {
        return {
            dropDown: false,
            dropDownMenu: false,
            dropMenuDesktop: false,
            showLogoutModal: false
        };
    },
    watch: {
        dropDown() {
            if (this.dropDown == true) {
                this.dropMenuDesktop = false;
            }
        },
        dropMenuDesktop() {
            if (this.dropMenuDesktop == true) {
                this.dropDown = false;
            }
        }
    },
    computed: {
        ...mapState({
            loginAbilitato: state => {
                return (
                    state.Ente.configs.login_pubblico &&
                    state.Ente.configs.login_pubblico.stato != 0
                );
            },
            user: state => state.User.loggedJwt
        }),
        hideLogoutButton() {
            if (
                this.user &&
                Cookies.get("hide_logout_button") &&
                !!Cookies.get("hide_logout_button") === true
            )
                return true;
            return false;
        },
        locales() {
            return process.env.VUE_APP_LOCALES.split(";");
        },
        version() {
            return process.env.VUE_APP_VERSION;
        },
        /**
         * @vuese
         * Rotta su cui reindirizzare al click sul nome del cliente
         */
        to() {
            return this.$route.matched.some(({ meta }) => !meta.external) ||
                (this.cliente && this.cliente.Codice)
                ? {
                      name: "HomeCliente",
                      params: { ente: this.cliente.Codice }
                  }
                : "/welcome";
        },
        /**
         * @vuese
         * stile della navbar
         */
        styleObject() {
            if (this.$route.matched.some(({ meta }) => meta.external)) {
                return {};
            }
            return {
                backgroundColor: this.$store.state.Ente.colors
                    ? this.$store.state.Ente.colors.sfondo[0]
                    : ""
            };
        }
    },
    methods: {
        ...mapMutations({
            setLocale: "Language/SET_LOCALE",
            logoutMutation: "User/LOGOUT"
        }),
        ...mapActions({
            logoutAction: "User/logoutAction"
        }),
        login() {
            localStorage.setItem("redirect", this.$route.name);
            this.$router.push({ name: "Login" });
        },
        /**
         * @vuese
         * Chiude dorpdown per schermi lg
         */
        closeMenuDesktop() {
            this.dropMenuDesktop = false;
        },
        /**
         * @vuese
         * chiude dropdown menu
         */
        closeLengMenu() {
            this.dropDown = false;
        },
        drop() {
            this.dropDown = !this.dropDown;
        },
        changeLanguage(value) {
            this.setLocale(value);
            if (this.cliente) {
                this.$i18n.locale = this.cliente.Codice + "_" + value;
                this.$i18n.fallbackLocale = value;
            } else {
                this.$i18n.locale = value;
            }
            document.querySelector("html").setAttribute("lang", value);
        },
        closeDropDownMenu() {
            this.dropDownMenu = false;
        },
        /**
         * @vuese
         * Mostra modale per conferma logout
         */
        logoutModal() {
            this.showLogoutModal = true;
        },
        /**
         * @vuese
         * chiude modale logout e chiama mutatation per effettuare il logout
         */
        logout() {
            this.showLogoutModal = false;
            this.logoutAction();
        }
    }
};
</script>

<style scoped>
.text-ultra-small {
    font-size: 0.6rem !important;
    /* margin-left: 5rem; */
    /* margin-top: 10px; */
    /* position: absolute; */
    padding: 0 10px;
    bottom: 0;
    color: #666;
}

.test-small {
    font-size: 0.8rem;
}

nav a {
    color: white;
    text-decoration: none;
}

h2 {
    color: black;
}

.pointer {
    cursor: pointer;
}

.dropdown-menu-custom {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #19191a;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid transparent;
    -webkit-box-shadow: 0 20px 30px 5px rgb(0 0 0 / 5%);
    box-shadow: 0 20px 30px 5px rgb(0 0 0 / 5%);
    z-index: 8;
}

.show-dropdown {
    display: block;
}

@media screen and (max-width: 600px) {
    .dropdown-menu-custom {
        width: 90vw;
    }
}

.custom-width {
    max-width: 250px;
    width: 250px;
    padding: 1rem 2rem;
}

/* .router-link-exact-active,
.router-link-active {
  color: #17324d;
} */
a.nav-link.dropdown-toggle:hover {
    color: white;
}

a.nav-link.dropdown-toggle {
    cursor: pointer;
}

.link-list li {
    border-bottom: 1px solid #7e7f807a;
}

.link-list li:last-of-type {
    border-bottom: 0px;
}
</style>
