<template>
  <vue-content-loading>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
  </vue-content-loading>
</template>

<script>
import { VueContentLoading } from "vue-content-loading";
export default {
  name: "select-loading",
  components: {
    VueContentLoading,
  },
};
</script>

<style>
</style>