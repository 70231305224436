import Cookies from "js-cookie";
export default {
    namespaced: true,
    state: {
        locale: localStorage.getItem("locale") || (process.env.VUE_APP_LOCALES.toUpperCase().split(";").includes(navigator.language.split('-')[0].toUpperCase()) ? navigator.language.split('-')[0] : null) || 'en',


    },
    getters: {},
    mutations: {
        SET_LOCALE(state, value) {
            state.locale = value;
            localStorage.setItem("locale", value);
            Cookies.set('locale', value);
        },

    },
};
