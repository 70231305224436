<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" ref="modal">
          <div class="d-flex flex-row-reverse px-4 py-2" tabindex="0" role="button" @click="$emit('close')" @keyup.enter="$emit('close')" v-if="closeBtn">
            <i class="fas fa-times text-danger" ></i>
          </div>
          <div class="modal-header" v-if="$slots.header">
            <slot name="header"> Header </slot>
          </div>

          <div class="modal-body">
            <slot name="body"> body </slot>
          </div>

          <div class="modal-footer" v-if="$slots.footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('delete')" @keyup.enter="$emit('delete')">
                {{ $t('buttons.elimina') }}
              </button>
              <button class="modal-default-button" @click="$emit('close')" @keyup.enter="$emit('close')">
                {{ $t('buttons.annulla') }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props : {
    closeBtn: {
      type : Boolean,
      default : false
    }
  },
  mounted() {
    document.addEventListener("click", this.clickOutside);
  },
  methods: {
    clickOutside(e) {
      e.preventDefault();
      if (e.target.classList.contains("modal-wrapper")) {
        this.$emit("close");
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutside);
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  /* font-family: Helvetica, Arial, sans-serif; */
}

.modal-header h3 {
  margin-top: 0;
  color: red;
}

.modal-body {
  width: 100%;
  /* min-height: 70vh; */
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media screen and (max-width: 600px) {
  .modal-container {
    width: 90%;
  }
}
</style>