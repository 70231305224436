<template>
  <div class="
      container-fluid
      d-flex
      flex-column
      justify-content-center
      align-items-center
    ">
    <img src="../../assets/easyload-xl.gif" :alt="$t('loading')" :width="width" />
    <p class="lead mt-2 text-muted" v-if="index != null && showText">
      {{ scritte[index] }}
    </p>
  </div>
</template>

<script>
export default {
  name: "loading-spinner",
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 200
    }
  },
  mounted() {
    if (this.showText) {
      setTimeout(() => (this.index = 0), 500);
      setTimeout(() => {
        this.index = 1;
      }, 4000);
      setTimeout(() => {
        this.index = 2;
      }, 5000);
    }
  },
  data() {
    return {
      scritte: this.$t('loading-text'),
      index: null,
    };
  },
};
</script>

<style>

</style>
