<template>
  <div>
    <div v-if="messages && open">
      <div class="alert alert-success mt-2 position-relative" ref="messages" tabindex="0">
        <ul class="list-unstyled">
          <li v-for="(message, i) in messages" :key="i">
            {{ getMessage(message) }}
          </li>
        </ul>
        <span class="close" @click="clear" @keyup.enter="clear"><i class="fas fa-times"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "ErrorBanner",
  computed: {
    ...mapState({
      messages: (state) => state.Utils.messages,
    }),
  },
  data() {
    return {
      open: true,
    };
  },
  mounted() {
    this.focus();
    setTimeout(() => {
      this.open = false;
      this.clear();
    }, 3000);
  },
  watch: {
    messages() {
      this.open = true;
      this.focus();
    },
  },

  methods: {
    ...mapMutations({
      clearMessages: "Utils/CLEAR_MESSAGES",
    }),
    focus() {
      this.$refs.messages.focus();
    },
    clear() {
      this.clearMessages();
    },
    getMessage(message) {
      if (Array.isArray(message)) {
        return this.$tc(...message);
      }
      return this.$t(message);
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  color: red;
  top: 0;
  right: 0;
  margin: 0.7rem;
}
</style>