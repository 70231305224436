<template>
    <div class="position-absolute position-fixed z-1000" style="right: 20px; bottom : 20px" v-if="!hide">

        <div class="feedback-modale position-relative bg-white" v-if="showFeedback">
            <div class="d-flex align-items-end justify-content-end"><i class="p-2 fas fa-times text-danger"
                    @click="showFeedback = false" @keyup.enter="showFeedback = false" tabindex="0"
                    aria-lable="chiudi modale"></i></div>
            <div class="feedback-modale--rating">
                <fieldset class="rating mb-2">
                    <label for="feedbackMsg" class="text-small mt-2">{{ $t('feedback.valutazione') }}</label>
                    <star-rating v-model="rating" :increment="1" :show-rating="false" :star-size="30"></star-rating>
                </fieldset>
                <label for="feedbackMsg" class="text-small mt-2">{{ $t('feedback.label_note') }}</label>
                <div class="position-relative">
                    <textarea required="" id="feedbackMsg" name="feedbackMsg" type="text"
                        :placeholder="$t('feedback.placeholder')" v-model="note" maxlength="250"></textarea>
                    <span class="position-absolute text-light" :class="note.length === 250 ? 'text-danger' : ''"
                        style="font-size : 10px ; right : 10px; bottom: 10px">{{
                            note.length
                        }} / 250</span>
                </div>

                <e-button class="btn btn-primary mt-3" @click="sendFeedback">{{ $t('buttons.confirm') }}</e-button>

            </div>
        </div>
        <span @click="showFeedback = true" @keyup.enter="showFeedback = true" v-if="!showFeedback"
            class="position-relative badge badge-light p-3 btn modal-toogler">
            <slot v-if="!done"></slot>
            <span v-else>{{ $t('feedback.grazie') }}</span>
        </span>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            entryRepeat: (state) => state.Entry.retEntry,
        })
    },
    data() {
        return {
            rating: 0,
            note: '',
            showFeedback: false,
            done: false,
            hide: false,
        }
    },

    methods: {

        sendFeedback() {
            this.$http.post(`/api/reservations/feedback`, {
                repeat : this.entryRepeat.map((item) => {
                   return item['repeat_id'];
                }),
                feedback: this.rating,
                feedback_note: this.note
            })
                .then(() => {
                    this.showFeedback = false;
                    this.done = true;
                    setTimeout(() => this.hide = true, 3000)
                })
        }
    }
}
</script>

<style>
.feedback-modale--rating--star {
    color: #eee;
}

.feedback-modale--rating--star-hover {
    color: gray;
}

.feedback-modale {
    border: 1px solid black;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.feedback-modale--rating {
    display: flex;
    flex-direction: column;
}

.z-1000 {
    z-index: 1000;
}
</style>
