import axios from "axios";
export default {
    namespaced: true,
    state: {
        loading: false,
        formLoading: false,
        form: null,
        formInputs: null,
        primaria: null,
        recaptchaToken: null,
        errors: {},
        messages: null,
        tourDone: localStorage.getItem("tour-done"),
        timePicker: false,
        selectedMonth: new Date().toJSON().slice(0, 10),
        monthSchedule: null,
        resourceGrid: false,
        session: null,
        durate: null,
        range: null,
        profiloAssegnato: false,
        formHeaderLabel: {
            name: 'empty',
            number: { passo: 1, di: 6 }
        },
        kiosk: false
    },
    getters: {},
    mutations: {
        SET_KIOSK_MODE(state, value) {
            state.kiosk = !!value;
        },
        /**
         *
         * @param {*} state
         * @param {name : string, passo : number} value
         */
        SET_FORM_HEADER_LABEL(state, value) {
            state.formHeaderLabel = value;
        },
        SET_PROFILO_ASSEGNATO(state, value = true) {
            localStorage.setItem('profilo_assegnato', true)
            state.profiloAssegnato = value;
        },
        SET_RANGE(state, value) {
            state.range = value;
        },
        SET_DURATE(state, value) {
            state.durate = value;
        },
        SET_RESOURCE_GRID(state, value) {
            state.resourceGrid = value;
        },
        SET_MONTHSCHEDULE(state, value) {
            state.monthSchedule = value;
        },
        SET_SELECTED_MONTH(state, value) {
            state.selectedMonth = value;
        },
        TOOGLE_LOADING(state, value = null) {
            if (value != null) {
                state.loading = value;
                return;
            }
            state.loading = !state.loading;
        },
        SET_TIME_PICKER(state, value) {
            state.timePicker = value;
        },
        TOOGLE_FORM_LOADING(state) {
            return new Promise(resolve => {
                state.formLoading = !state.formLoading;
                resolve();
            });
        },
        SET_ERRORS(state, error) {
            state.errors = error;
        },
        CLEAR_ERRORS(state) {
            state.errors = null;
        },
        SET_MESSAGES(state, value) {
            if (state.messages == null) {
                state.messages = {};
            }
            state.messages[value.name] = value.message;
            return new Promise(resolve => setTimeout(resolve(), 3000));
        },
        CLEAR_MESSAGES(state) {
            state.messages = null;
        },
        SET_FORM(state, value) {
            state.form = value;
            state.primaria = value.filter(e => e.primaria == true)[0]
                ? value.filter(e => e.primaria == true)[0].codice
                : null;
        },
        SET_RECAPTCHATOKEN(state, value) {
            state.recaptchaToken = value;
        },
        SET_TOUR_DONE(state, value = true) {
            if (value == false) {
                localStorage.removeItem("tour-done");
                state.tourDone = null;
            } else {
                state.tourDone = true;
                localStorage.setItem("tour-done", true);
            }
        },
        SET_FORMINPUTS(state, value) {
            state.formInputs = value;
        },
        RESET_FORMINPUTS(state) {
            state.formInputs = null;
        },
        SET_SESSION(state, value) {
            state.session = value;
        },
        RESET_ERRORS(state) {
            state.errors = null;
        }
    },
    actions: {
        init({ dispatch, commit }, ente) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/cliente/show/${ente}`)
                    .then(res => {
                        commit("Ente/SET_CLIENTE", res.data, { root: true });
                        dispatch("Ente/fetchColors", null, { root: true })
                            .then(() => {
                                resolve();
                            })
                            .catch(() => reject());
                    })
                    .catch(() => reject());
            });
        }
    }
};
