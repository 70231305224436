<template>
  <button class="btn"
    :class="[(disabled || selfDisabled) ? 'disabled' : '', danger ? 'btn-outline-danger text-danger' : 'btn-primary']"
    @click.prevent="click" @keyup.enter="click" :style="styleObject">
    <slot></slot>
  </button>
</template>

<script>
import { mapState } from "vuex";
import { colors } from '../../mixins/colors'

export default {
  mixins: [colors],

  name: "EButton",
  props: {
    outlined: {
      type: Boolean,
    },
    color: {
      type: Number,
      required: false,
    },
    submit: {
      type: Boolean,
      default: true
    },
    danger: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dontDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      colors: (state) => state.Ente.colors.sfondo,
    }),
    computedColor() {
      return this.color ? this.colors[this.color] : this.colors[0];
    },

    styleObject() {
      const style = {
        boxSizing: "border-box",
        border: this.danger ? null : "2px solid " + this.computedColor,
        backgroundColor: this.danger ? null : this.computedColor,
        color: this.getContrastText(this.computedColor)
      };

      if (this.outlined) {
        style.backgroundColor = "#ffffff";
        style.color = this.computedColor;
        return style;
      }


      return style;
    },
  },
  data() {
    return {
      selfDisabled: false,
    }
  },
  methods: {
    click() {
      if (this.submit && !this.dontDisable) {
        this.selfDisabled = true;
      }
      this.$emit('click')
    }
  }
};
</script>

<style></style>