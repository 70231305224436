<template>
  <div class="it-header-wrapper" role="navigation">
    <a class="skip-main" href="#main" tabindex="0">{{ $t('vai_al_contenuto_principale') }}</a>
    <nav-bar-header></nav-bar-header>
    <nav-bar-small></nav-bar-small>
  </div>
</template>

<script>
import NavBarHeader from "./navbarComponents/NavBarHeader";
import NavBarSmall from "./navbarComponents/NavBarSmall";
/**
 * @vuese
 * @group Navbar
 * Raccoglie i due elementi della navbar in un unico componente
 */
export default {
  name: "NavBar",
  components: {
    NavBarHeader,
    NavBarSmall,
  },
};
</script>

<style scoped>
a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a.skip-main:focus,
a.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}
</style>