
import {store} from '../services'
const ServiziBrevi = () =>
    import(/* webpackChunkName: "prenota" */ "../../views/ServiziBrevi.vue");
import SelectPageServiziBrevi from "../../components/serviziBrevi/SelectPage.vue"

const Riepilogo = () =>
    import(
        /* webpackChunkName: "riepilogo" */ "../../components/form/Riepilogo.vue"
    );
const DatiUtente = () =>
    import(/* webpackChunkName: "form" */ "../../components/form/Form.vue");

const Calendario = () =>
    import(
        /* webpackChunkName: "calendario" */ "../../components/form/Calendario.vue"
    );

const Confirmed = () =>
    import(
    /* webpackChunkName: "conferma" */ "../../components/form/Confirmed.vue"
    );
export default
    [{
        path: "/:ente/servizi_brevi",
        name: "servizi_brevi",
        component: ServiziBrevi,
        meta: { translateBasedOnCliente: true },
        children: [
            {
                path: "servizi",
                name: "servizi_brevi.servizi",
                component: SelectPageServiziBrevi,
                beforeEnter(_, from, next) {
                    store.commit("Utils/SET_FORM_HEADER_LABEL", {
                        name: "form.menu.servizio", number: { passo: 1, di: 5 }, prev: {
                            name: "HomeCliente",
                            params: {
                                ente: from.params.ente
                            }
                        }
                    })
                    next()
                }

            },
            {
                path: "calendario/:servizio/:area",
                name: "servizi_brevi.Calendario",
                component: Calendario,
                meta: { requiresStore: true },
                beforeEnter(_, __, next) {
                    store.commit("Utils/SET_FORM_HEADER_LABEL", {
                        name: "form.menu.data", number: { passo: 2, di: 5 }, prev: {
                            name: "servizi_brevi.servizi",
                        }
                    })
                    next()
                }
            },
            {
                path: "dati",
                name: "servizi_brevi.DatiUtente",
                component: DatiUtente,
                meta: { keepAlive: true, requiresStore: true },
                beforeEnter(_, __, next) {
                    if(store.state.Utils.session) {
                        const session = store.state.Utils.session
                        store.commit('Entry/SET_ENTRY',session.Entry);
                        store.commit('Utils/SET_FORMINPUTS',session.Utils.formInputs);
                        store.commit('Utils/SET_FORM',session.Utils.form);
                    }
                    store.commit("Utils/SET_FORM_HEADER_LABEL", {
                        name: "form.menu.dati_utente", number: { passo: 3, di: 5 }, prev: {
                            name: "servizi_brevi.Calendario",
                            params : {
                                servizio : store.state.Entry.servizio.id,
                                area : store.state.Entry.area.id
                            }
                        }
                    })
                    next()
                }
            },

            {
                path: "riepilogo",
                name: "servizi_brevi.Riepilogo",
                component: Riepilogo,

                meta: { requiresStore: true },
                beforeEnter(_, __, next) {
                    store.commit("Utils/SET_FORM_HEADER_LABEL", {
                        name: "form.menu.riepilogo", number: { passo: 4, di: 5 }, prev: {
                            name: "servizi_brevi.DatiUtente",
                        }
                    })
                    next()
                }
            },
            {
                path: "confirmed",
                name: "servizi_brevi.Confirmed",
                component: Confirmed,
                meta: { requiresStore: true , redirect : "servizi_brevi.servizi"},
                beforeEnter(_, __, next) {
                    store.commit("Utils/SET_FORM_HEADER_LABEL", {
                        name: "form.menu.conferma", number: { passo: 5, di: 5 }
                    })
                    next()
                }
            }

        ]
    }
    ]
