import {i18n} from "../i18n";
export const secondsToString = {
    computed: {
        lang() {
            const array = i18n.locale.split("_");
            const length = array.length;
            return array[length - 1];
        }
    },
    methods: {
        secondsToString(seconds, showSeconds = false) {
            // console.log(this.lang)
            const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
            const numminutes = Math.floor(
                (((seconds % 31536000) % 86400) % 3600) / 60
            );
            const secs = ~~seconds % 60;
            let str = "";
            // if (self.lang == "it") {
            //     if (numhours && numhours > 1) {
            //         str += numhours + " ore";
            //     } else if (numhours && numhours == 1) {
            //         str += numhours + " ora";
            //     }

            //     return (
            //         str + " " + (numminutes > 0 ? numminutes + " minuti" : " ")
            //     );
            // }
            if (numhours && numhours > 1) {
                str += numhours + ' ' + this.$t('hours');
            } else if (numhours && numhours == 1) {
                str += numhours + ' ' + this.$t('hour');
            }
            str = str + " " + (numminutes > 0 ? numminutes + ' ' + this.$t('minutes') : " ");
            if(!showSeconds) return str;
            return str + " " + (secs > 0 ? secs + ' ' + this.$t('seconds') : " ");
            
        }
    }
};
