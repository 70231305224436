<template>
    <th
        scope="col"
        :role="sortable ? 'button' : ''"
        @click="$emit('sortBy', orderKey)"
        @keypress.enter="$emit('sortBy', orderKey)"
    >
        {{ label }}
        <template v-if="sortable">
            <span class="sr-only"> {{$t('screen_readers.clicca_per_ordinare')}} </span>
            <i class="fas fa-sort-amount-down-alt" v-if="order == 'asc'"></i>
            <i class="fas fa-sort-amount-up" v-else></i>
        </template>
    </th>
</template>
<script>
export default {
    name: "TableHeader",
    props: {
        sortable: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        order: {
            type: String,
            default: "asc"
        },
        orderKey : {
            type : String
        }
    },
    emits: ["sortBy"]
};
</script>
