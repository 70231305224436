<template>
  <div class="container mt-5">
    <div class="row mt-5">
      <h1 class="display-4">{{ $t('logout.success') }}</h1>
      <p class="lead">{{ $t('logout.message') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logout",
};
</script>

<style>
</style>