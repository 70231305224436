<template>
    <card :cardName="'prenota'" :color="config.color" :link="$routes.PRENOTA.SERVIZI" :style="style" :size="config.size">
        <template v-slot:icon>
            <img src="@/assets/images/nuova_prenotazione.svg" class="my-3 p-2" style="width: 300px" alt="icona" />
        </template>

        <template v-slot:title>
            <h5>
                {{ $t("nuova_prenotazione.title") }}
            </h5>
        </template>
        <template v-slot:description>
            <div class="card-text text-sans-serif">
                {{ $t("nuova_prenotazione.description") }}
            </div>
        </template>
</card>
</template>

<script>
export default {
    props: {
        config: {
            required: true,
            type: Object
        }
    },
    computed: {
        style() {
            return {
                gridArea: this.config.position,
                order: this.config.order,
            }
        }
    }
}
</script>

<style></style>