<template>
    <div class="">
        <div class="accordion p-3" id="prenotazioni">
            <div
                class="card"
                v-for="(item, index) in items"
                :key="keyName ? item[keyName] : index"
                :id="index"
                :ref="refName + '_' + index"
            >
                <div class="" :id="'heading' + index">
                    <h2
                        tabindex="0"
                        class="sr-only"
                        type="button"
                        @click="toggle(index)"
                        @keypress.enter="toggle(index)"
                        :aria-expanded="isActive(index)"
                        :aria-controls="'collapse' + index"
                    >
                        <slot name="sr-title" :item="item"> </slot>
                    </h2>
                    <span
                        @click="toggle(index)"
                        @keypress.enter="toggle(index)"
                    >
                        <slot
                            name="title"
                            :item="item"
                            :toggle="toggle"
                            :index="index"
                            :isActive="isActive(index)"
                            :classes="{ active: isActive(index) }"
                        >
                            <component
                                :is="item.title"
                                v-bind="item.titleProps"
                            />
                        </slot>
                    </span>
                </div>

                <div
                    :id="'collapse' + index"
                    class="collapse"
                    :class="{ show: isActive(index) }"
                    :aria-labelledby="'heading' + index"
                    tabindex="0"
                >
                    <div class="card--body">
                        <slot name="body" :item="item" :index="index">
                            <component
                                :is="item.content"
                                v-bind="item.contentProps"
                            />
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Accordion",
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        },
        refName: {
            type: String,
            required: false,
            default: () => "item"
        },
        keyName: {
            type: String,
            required: false
        },
        allOpen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            activeIndex: null
        };
    },
    mounted() {
        if (this.items.length <= 1 && !this.allOpen) {
            this.activeIndex = 0;
        }
    },
    methods: {
        toggle(index) {
            if (this.allOpen) {
                this.$set(this.activeItems, index, !this.activeItems[index]);
            } else {
                this.activeIndex = this.activeIndex === index ? null : index;
            }
        },
        isActive(index) {
            return this.allOpen
                ? this.activeItems[index]
                : this.activeIndex === index;
        }
    },
    computed: {
        activeItems() {
            return this.items.map(() => this.allOpen);
        }
    }
};
</script>
