<template>
  <div class="row">
    <div class="col-12 mt-5 v-wh">
        <message messageKey="messaggio-home" v-if="showMessage" @close="closeMessage" closeBtn />
      <div class="home-grid" :style="{ gap: configCards.gap }">

        <prenota :config="configCards.prenota" />
        <gestisci :config="configCards.gestisci" />
        <qr-code :config="configCards.qr_scan" v-if="configCards.qr_scan && configs.portale_pubblico.scansiona_qr" />
        <riprenota :config="configCards.riprenota_rapido" v-if="lastReservation &&
          configs.portale_pubblico.riprenota_rapido
          && configs.portale_pubblico.riprenota_rapido == 1" :lastReservation="lastReservation" />
        <servizi-brevi :config="configCards.servizi_brevi" v-if="configs.general.servizi_brevi == 1" />
        <!-- EXTRA -->
        <card :cardName="extraCard.title" v-for="(extraCard, i) in configCards.extra" :key="i"
          :external-link="extraCard.link" :style="{
            gridArea: extraCard.position,
            order: extraCard.order,
          }" :size="extraCard.size" :color="extraCard.color">
          <template v-slot:title v-if="extraCard.title">
            <h5>
              {{ $t(extraCard.title) }}
            </h5>
          </template>
          <template v-slot:icon v-if="extraCard.icon">
            <img :src="extraCard.icon" class="my-3 p-2" alt="icona" />
          </template>
          <template v-slot:description v-if="extraCard.description">
            <div class="card-text text-sans-serif">
              {{ $t(extraCard.description) }}
            </div>
          </template>
        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { mapState } from "vuex";
import Prenota from "../components/Cards/Prenota";
import Gestisci from "../components/Cards/Gestisci";
import QrCode from "../components/Cards/QrCode.vue";
import Riprenota from "../components/Cards/Riprenota.vue";
import ServiziBrevi from "../components/Cards/ServiziBrevi.vue";
// @group Views
//Pagina iniziale del singolo cliente
export default {
  name: "HomeCliente",
  components: {
    Prenota,
    Gestisci,
    QrCode,
    Riprenota,
    ServiziBrevi
  },
  data() {
    return {
      showMessage:
        localStorage.getItem("showMessage") == undefined &&
        this.$t("messaggio-home") != "messaggio-home",
      lastReservation: null
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.Utils.loading,
      user: (state) => state.User.loggedJwt,
    }),
    configCards() {
      return this.configs.portale_pubblico.layout_home;
    },
  },
  mounted() {
    if (this.user && this.configs.portale_pubblico.riprenota_rapido) this.getLastBooking()
    if (this.user && localStorage.getItem("greeting-uses") == undefined) {
      localStorage.setItem("greeting-uses", true);
      this.setMessage({
        name: "logged",
        message: this.setMessage({
          name: "success",
          message: this.$t("login.success", {
            nome: this.user["cognome_nome"],
          }),
        }),
      });
    }
  },
  methods: {
    closeMessage() {
      this.showMessage = false;
      localStorage.setItem("showMessage", false);
    },
    async getLastBooking() {
      this.isLoading = true;
      const res = await this.$http.post(
        `api/reservations/last/${this.cliente.id}`,
        {
          user: Cookies.get("user"),
        }
      );
      this.lastReservation = {
        area: res.data.entry.risorsa.area.id,
        area_name: res.data.entry.risorsa.area.area_name,
        area_image: res.data.entry.risorsa.area.area_image,
        servizio: res.data.entry.servizio_id,
        servizio_name: res.data.entry.servizio
      }
    },
  },
};
</script>

<style>
.close-allert-btn {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
  color: red;
  padding: 10px;
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

@media screen and (max-width: 600px) {
  .home-grid {
    display: flex;
    flex-direction: column;
  }
}
</style>
