<template>
  <div class="container mt-5 text-center">
    <div class="row">
      <div class="col-12">
        <h1 class="h3">{{$t('error-page.title')}}</h1>
        <button @click="$router.go(-1)" @keyup.enter="$router.go(-1)" class="btn btn-lg btn-primary m-4">{{$t('error-page.retry')}}</button>
        <p class="mt-2 text-muted">{{$t('error-page.get-assistance')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",

};
</script>

<style>
</style>