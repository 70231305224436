<template>
    <div class="col-md-8 col-12 mt-5 mx-auto">
        <error-banner v-if="errors" close />

        <div class="card card-outline-secondary border">
            <div class="card-header">
                <h3 class="mb-0">{{ $t("reset_password.title") }}</h3>
            </div>
            <div class="card-body">
                <form
                    method="POST"
                    action="/"
                    class="form"
                    role="form"
                    autocomplete="off"
                >
                    <div
                        class="form-group"
                        :class="{ 'form-group--error': $v.password.$error }"
                    >
                        <span for="password" class="label">{{
                            $t("reset_password.new_password")
                        }}</span>

                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            name="password"
                            required
                            v-model.trim="$v.password.$model"
                            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                        />
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <password
                                v-model="password"
                                :strength-meter-only="true"
                                class="w-full"
                            />
                            <VDropdown :distance="6">
                                <span
                                    class="badge badge-pill  "
                                    :class="
                                        +$v.password.$invalid
                                            ? 'badge-danger'
                                            : 'badge-success'
                                    "
                                >
                                    <i class="fa fa-info p-1"></i>
                                </span>
                                <template v-slot:popper>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            {{
                                                $t("reset_password.min_length")
                                            }}
                                            <span class="ml-2">
                                                <i
                                                    class="fas fa-times text-danger"
                                                    v-if="
                                                        !$v.password.minLength
                                                    "
                                                ></i>
                                                <i
                                                    class="fa fa-check text-success"
                                                    v-else
                                                ></i>
                                            </span>
                                        </li>
                                        <li class="list-group-item">
                                            {{
                                                $t(
                                                    "reset_password.capital_letters"
                                                )
                                            }}
                                            <span class="ml-2">
                                                <i
                                                    class="fas fa-times text-danger"
                                                    v-if="
                                                        !$v.password
                                                            .mustHaveCapitalizedLetter
                                                    "
                                                ></i>
                                                <i
                                                    class="fa fa-check text-success"
                                                    v-else
                                                ></i>
                                            </span>
                                        </li>
                                        <li class="list-group-item">
                                            {{ $t("reset_password.numbers") }}
                                            <span class="ml-2">
                                                <i
                                                    class="fas fa-times text-danger"
                                                    v-if="
                                                        !$v.password
                                                            .mustHaveNumber
                                                    "
                                                ></i>
                                                <i
                                                    class="fa fa-check text-success"
                                                    v-else
                                                ></i>
                                            </span>
                                        </li>
                                        <li class="list-group-item">
                                            {{
                                                $t(
                                                    "reset_password.special_characters"
                                                )
                                            }}
                                            <span class="ml-2">
                                                <i
                                                    class="fas fa-times text-danger"
                                                    v-if="
                                                        !$v.password
                                                            .mustHaveSpecialCharacter
                                                    "
                                                ></i>
                                                <i
                                                    class="fa fa-check text-success"
                                                    v-else
                                                ></i>
                                            </span>
                                        </li>
                                        <li class="list-group-item">
                                            {{
                                                $t(
                                                    "reset_password.no_user_name"
                                                )
                                            }}
                                        </li>
                                    </ul>
                                </template>
                            </VDropdown>
                        </div>
                        <div v-if="$v.password.$dirty">
                            <div class="error" v-if="!$v.password.required">
                                {{ $t("errors.required") }}
                            </div>
                            <div class="error" v-if="!$v.password.minLength">
                                {{ $t("errors.min_password") }}
                            </div>
                            <div
                                class="error"
                                v-if="!$v.password.mustHaveSpecialCharacter"
                            >
                                {{ $t("reset_password.special_characters") }}
                            </div>
                            <div
                                class="error"
                                v-if="!$v.password.mustHaveNumber"
                            >
                                {{ $t("reset_password.numbers") }}
                            </div>
                            <div
                                class="error"
                                v-if="!$v.password.mustHaveCapitalizedLetter"
                            >
                                {{ $t("reset_password.capital_letters") }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group"
                        :class="{
                            'form-group--error': $v.repeatPassword.$error
                        }"
                    >
                        <span for="inputPasswordNewVerify" class="label">{{
                            $t("reset_password.verify_password")
                        }}</span>
                        <input
                            type="password"
                            class="form-control"
                            id="confirm_password"
                            v-model.trim="$v.repeatPassword.$model"
                            required
                        />
                        <div
                            class="error"
                            v-if="!$v.repeatPassword.sameAsPassword"
                        >
                            {{ $t("errors.password_not_match") }}
                        </div>
                    </div>
                    <div class="form-group">
                        <button
                            type="submit"
                            class="btn btn-primary btn-lg btn-block"
                            @click.prevent="submit"
                            @keyup.enter.prevent="submit"
                        >
                            {{ $t("buttons.confirm") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Password from "vue-password-strength-meter";
const mustHaveSpecialCharacter = value => {
    const regex = /[@$!%*#?&]/;
    return regex.test(value);
};
const mustHaveNumber = value => {
    const regex = /\d/;
    return regex.test(value);
};
const mustHaveCapitalizedLetter = value => {
    const regex = /[A-Z]/;
    return regex.test(value);
};
export default {
    components: { Password },
    data() {
        return {
            password: "",
            repeatPassword: ""
        };
    },
    validations: {
        password: {
            required,
            minLength: minLength(8),
            mustHaveSpecialCharacter,
            mustHaveNumber,
            mustHaveCapitalizedLetter
        },
        repeatPassword: {
            sameAsPassword: sameAs("password")
        }
    },
    computed: {
        token() {
            return this.$route.params.token;
        }
    },
    methods: {
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return this.setError({ message: "form.field_error" });
            }
            this.$http
                .post("/api/auth/recupera-password/store", {
                    password: this.password,
                    auth_token: this.token
                })
                .then(() => {
                    this.setMessage({
                        name: "success",
                        message: this.$t("reset_password.success_message")
                    });
                    this.$router.push({ name: "Login" });
                });
        }
    }
};
</script>

<style scoped>
.form-group--error input,
.form-group--error textarea,
.form-group--error input:focus,
.form-group--error input:hover {
    border-color: #f79483;
}
.form-group--error .label {
    color: #f79483;
}
</style>
