<template>
    <card :cardName="'riprenota'" :color="config.color" :link="{
        name: $routes.PRENOTA.CALENDARIO, params: {
            servizio: lastReservation.servizio,
            area: lastReservation.area
        }
    }" :style="style" :size="config.size">
        <template v-slot:title v-if="config.size == 'xs'">
            <div class="d-flex flex-column">
                {{ $t("prenotazione_rapida.title") }} 
            <span class="font-weight-bold">
                <span v-html="lastReservation.servizio_name"></span>
                -
                <span v-html="lastReservation.area_name"></span>

            </span>
            </div>
        </template>
        <template v-slot:title v-else>
            <h5>
                {{ $t("prenotazione_rapida.title") }}
            </h5>
        </template>

        <template v-slot:description>
            <div class="card-text text-sans-serif">
                {{ $t("prenotazione_rapida.description") }}
                <span class="font-weight-bold">
                    <span v-html="lastReservation.servizio_name"></span>
                    -
                    <span v-html="lastReservation.area_name"></span>
                </span>
            </div>
        </template>


</card>
</template>

<script>
export default {
    props: {
        config: {
            required: true,
            type: Object
        },
        lastReservation: {
            required: true,
            type: Object
        }
    },
    computed: {
        style() {
            return {
                gridArea: this.config.position,
                order: this.config.order,
            }
        }
    }
}
</script>

<style></style>