<template>
  <div class="container my-5">
    <error-banner v-if="errors" close />

    <div class="row">
      <div class="col-md-6 col-12 mx-auto text-center">
        <div class="row">
          <form class="form-signin">
            <div v-if="metodo == 'local' || metodo == 'esse3' || metodo == 'ldap' || metodo == 'form'">
              <h1 class="h3 mb-3 font-weight-bold mx-auto">{{ $t('menu.login') }}</h1>
              <h2 v-if="metodo == 'esse3'">
                {{ $t('login.esse3') }}
              </h2>
              <!-- <p>
                Nel caso di problemi relativi all'accesso o necessità di
                informazioni in merito ai servizi offerti contatta la tua
                Facoltà di riferimento o scrivi a
                "helpdesk.studenti.efis@unica.it"
              </p> -->
              <div class="form">
                <label for="inputEmail" class="sr-only">{{
                  $t("login.mail")
                }}</label>
                <input v-model="form.email" type="text" id="inputEmail" name="inputEmail" class="form-control"
                  placeholder="Username" autocomplete="on" required />

                <label for="inputPassword" class="sr-only">{{
                  $t("login.password")
                }}</label>
                <div class="input-password">
                  <input ref="password" v-model.trim="form.password" type="password" id="inputPassword"
                    name="inputPassword" class="form-control" placeholder="Password" required autocomplete="on" />
                  <i @click="showPassword" @keyup.enter="showPassword" class="far fa-eye" id="togglePassword"></i>
                </div>
                <e-button :disabled="form.email.length == 0 ||
                  form.password.length == 0 ||
                  loading
                  " @click="submitForm(metodo)" @keyup.enter="submitForm(metodo)" :dontDisable="true"
                  class="btn btn-lg btn-primary btn-block mt-5" type="submit">
                  {{ $t('login.accedi') }}
                  <i class="fas fa-spinner fa-pulse" v-if="loading"></i>
                </e-button>
                <p v-if="metodo == 'local'" class="mt-2 text-primary cursor-p" @click="showModaleRecuperoPassword"
                  @keyup.enter="showModaleRecuperoPassword">
                  {{ $t('login.recupera_password.title') }}
                </p>
                <RecuperaPassword v-if="modaleRecuperoPassword" @closed="modaleRecuperoPassword = false"
                  :current-account="form.email" />
              </div>
            </div>
            <div v-if="metodo == 'zucchetti'">
              <p>
                {{ $t('login.zucchetti') }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ErrorBanner from "../components/ErrorBanner.vue";
import RecuperaPassword from "../components/auth/RecuperaPassword";

// @group Views
//Pagina di login
export default {
  components: {
    ErrorBanner,
    RecuperaPassword,
  },
  beforeRouteLeave(_, __, next) {
    this.clearErrors();
    next();
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      modaleRecuperoPassword: false,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      metodo: (state) => state.Ente.configs.login_pubblico.metodo,
      errors: (state) => state.Utils.errors,
      cliente: (state) => state.Ente.cliente,
      loginObbligatorio: (state) =>
        state.Ente.configs.login_pubblico.stato &&
        state.Ente.configs.login_pubblico.stato === "obbligatorio",
    }),
  },
  watch: {
    errors() {
      if (this.errors && this.modaleRecuperoPassword) {
        this.modaleRecuperoPassword = false;
      }
    }
  },
  mounted() {
    if (this.$route.query.error) {
      this.setErrors({
        message: this.$route.query.error
      });

    }
  },
  methods: {
    ...mapMutations({
      setErrors: "Utils/SET_ERRORS",
      clearErrors: "Utils/CLEAR_ERRORS",
      resetErrors: "Utils/RESET_ERRORS",
    }),
    showModaleRecuperoPassword() {
      this.modaleRecuperoPassword = true;
      this.resetErrors()
    },
    showPassword() {
      const password = this.$refs.password;
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },
    submitForm(metodo) {

      if (metodo == "esse3" || metodo == "local" || metodo == 'ldap' || metodo == 'form') {
        this.loading = true;
        this.setItem(this.cliente.Codice);
        // next()
        this.$http
          .post(`/api/auth/${this.cliente.Codice}/login`, {
            username: this.form.email,
            password: this.form.password
          })
          .then(() => {
            if (this.errors) return;
            let redirect = localStorage.getItem("redirect");
            if (this.loginObbligatorio) {
              redirect = "HomeCliente";
            }
            if (metodo === 'local' || metodo === 'esse3') return this.$router.push({ name: "HomeCliente", ente: localStorage.getItem("redirect") });
            this.$router.push({ name: redirect });
          }).catch(() => {
            this.loading = false;
          });
      }
    },
    setItem(item) {
      localStorage.setItem("redirect", item);
    },
  },
};
</script>

<style scoped>
.input-password {
  position: relative;
}

.input-password i {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 1rem;
}
</style>
