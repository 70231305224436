import { store } from '../services'

const Prenota = () =>
    import(/* webpackChunkName: "prenota" */ "../../views/Prenota.vue");
import SelectPage from '../../components/form/SelectPage.vue';
const Riepilogo = () =>
    import(
        /* webpackChunkName: "riepilogo" */ "../../components/form/Riepilogo.vue"
    );
const DatiUtente = () =>
    import(/* webpackChunkName: "form" */ "../../components/form/Form.vue");
const Mappa = () =>
    import(/* webpackChunkName: "mappa" */ "../../components/form/Mappa.vue");
const Calendario = () =>
    import(
        /* webpackChunkName: "calendario" */ "../../components/form/Calendario.vue"
    );

const Confirmed = () =>
    import(
    /* webpackChunkName: "conferma" */ "../../components/form/Confirmed.vue"
    );
export default [{
    path: "/:ente/prenota",
    name: "Prenota",
    component: Prenota,
    meta: { translateBasedOnCliente: true },
    beforeEnter(_, from, next) {
        if (from.name == "HomeCliente") {
            store.commit("Entry/RESET_ENTRY");
            store.commit("Ente/POPULATE_AREE", null);
        }
        next();
    },
    children: [
        {
            path: "servizi",
            name: "servizi",
            component: SelectPage,
            beforeEnter(_, from, next) {
                store.commit("Utils/SET_FORM_HEADER_LABEL", {
                    name: "form.menu.servizio", number: { passo: 1, di: 6 }, prev: {
                        name: "HomeCliente",
                        params: {
                            ente: from.params.ente
                        }
                    }
                })
                if (from.name == 'HomeCliente') {
                    store.commit('Entry/RESET_ENTRY');
                }
                next()
            }
            // component: ServiziArea
            // meta: { keepAlive: true }
        },
        {
            path: "mappa",
            name: "Mappa",
            component: Mappa,
            beforeEnter(_, __, next) {
                store.commit("Utils/SET_FORM_HEADER_LABEL", {
                    name: "form.menu.servizio", number: { passo: 2, di: 6 },
                    prev: {
                        name: "servizi",
                    }
                })
                next()
            }
        },

        {
            path: "calendario/:servizio/:area",
            name: "Calendario",
            component: Calendario,
            meta: { requiresStore: true },
            beforeEnter(_, __, next) {
                store.commit("Utils/SET_FORM_HEADER_LABEL", {
                    name: "form.menu.data", number: { passo: 2, di: 6 }, prev: {
                        name: "servizi",
                    }
                })
                next()
            }
        },
        // passo 3 è gestito in calendario
        {
            path: "dati",
            name: "DatiUtente",
            component: DatiUtente,
            meta: { keepAlive: true, requiresStore: true },
            beforeEnter(_, __, next) {
                if (store.state.Utils.session) {
                    const session = store.state.Utils.session
                    store.commit('Entry/SET_ENTRY', session.Entry);
                    store.commit('Utils/SET_FORMINPUTS', session.Utils.formInputs);
                    store.commit('Utils/SET_FORM', session.Utils.form);
                }
                store.commit("Utils/SET_FORM_HEADER_LABEL", {
                    name: "form.menu.dati_utente", number: { passo: 4, di: 6 }, prev: {
                        name: "Calendario",
                        params: {
                            servizio: store.state.Entry.servizio.id,
                            area: store.state.Entry.area.id
                        }
                    }
                })
                next()
            }
        },
        {
            path: "riepilogo",
            name: "Riepilogo",
            component: Riepilogo,
            meta: { requiresStore: true },
            beforeEnter(_, __, next) {
                store.commit("Utils/SET_FORM_HEADER_LABEL", {
                    name: "form.menu.riepilogo", number: { passo: 5, di: 6 }, prev: {
                        name: "DatiUtente",
                    }
                })
                next()
            }
        },
        {
            path: "confirmed",
            name: "Confirmed",
            component: Confirmed,
            meta: { requiresStore: true, redirect: "servizi" },
            beforeEnter(_, __, next) {
                const name = store.state.Entry.servizio.reservation_type != 'CONFERMATA' ? 'form.menu.richiesta' : 'form.menu.conferma'
                store.commit("Utils/SET_FORM_HEADER_LABEL", {
                    name: name, number: { passo: 6, di: 6 }
                })
                next()
            }
        }
    ]
}
]
