<template>
    <card :cardName="'scan_qr_code'" :color="config.color" :style="style" :size="config.size" :to="$routes.QRSCAN">
        <template v-slot:title>
            <h5 class="d-flex flex-column align-items-center">
                <i class="fas fa-qrcode fa-3x"></i>
                {{ $t('qr_code.card_title') }}
            </h5>
        </template>
    </card>
</template>

<script>
export default {
    props: {
        config: {
            required: true,
            type: Object
        }
    },
    computed: {
        style() {
            return {
                gridArea: this.config.position,
                order: this.config.order,
            }
        }
    }
}
</script>

<style></style>