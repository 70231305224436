import store from '../store/index';

class LoadingManager {

    get last_modified() {
        return this._last_modified.toLocaleDateString();
    }

    constructor() {
        if (LoadingManager.instance) {
            return LoadingManager.instance;
        }

        this.loading = store.state.Utils.loading;
        this._last_modified = new Date();
    }

    static getInstance() {
        return LoadingManager.instance || new LoadingManager();
    }

    toggle(value = null) {
        if (value) this.loading = value;
        else this.loading = !this.loading;
        this._last_modified = new Date();
        store.commit("Utils/TOOGLE_LOADING");
    }

}


const loading = LoadingManager.getInstance();

export const getLoading = loading;
export const toggleLoading = (state = null) => loading.toggle(state)

