import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import translateBasedOnCliente from "./guards/TranslateBasedOnCliente";
import requiresManage from "./guards/RequiresManage";
import RedirectHome from "./guards/RedirectHome";
import EvadiConQr from "./guards/EvadiConQr";
//Utils
import { Cookie, parseJwt } from "./services";
import { routes } from "./routes/routes";
import { toggleLoading } from "../mixins/loading";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.name === "NotFound") {
            return {
                selector: "#notFound"
            };
        }
        if (to.matched.some(({ name }) => name === "Prenota")) {
            return {
                selector: "#navbar-sm"
            };
        }
        return { x: 0, y: 0 };
    }
});

router.beforeEach(async (to, _, next) => {
    if (to.query.kiosk) {
        store.commit("Utils/SET_KIOSK_MODE", to.query.kiosk);
    }

    if (to.meta.next) {
        return next();
    }
    if (to.meta.requiresStore) {
        RedirectHome({ to, next });
    }

    toggleLoading();

    translateBasedOnCliente(to);
    requiresManage({ to, next });
    if (Cookie.get("user")) {
        const userAttributes = parseJwt(Cookie.get("user"));
        store.commit("User/SET_IS_LOGGED");
        store.commit("User/SET_LOGGEDJWT", userAttributes);
    }
    if (!store.state.Ente.cliente) {
        store.dispatch("Ente/fetchCliente", to);
    }
    EvadiConQr(to, next);

    if (
        to.name == "Home" ||
        to.name == "Logout" ||
        to.name == "Error" ||
        to.name == "ResetPassword"
    ) {
        toggleLoading();
        return next();
    }
    if (
        (!store.state.Ente.cliente && !store.state.Ente.colors) ||
        store.state.Ente.cliente.Codice != to.params.ente
    ) {
        return store.dispatch("Ente/fetchCliente", to).then(() => {
            if (
                store.state.Ente.configs.login_pubblico.stato &&
                store.state.Ente.configs.login_pubblico.stato ==
                "obbligatorio" &&
                !Cookie.get("user") &&
                to.name != "Login"
            ) {
                router.push({
                    name: "Login",
                    params: {
                        ente: to.params.ente
                    }
                });
            }

            toggleLoading();

            next();
        });
    }

    if (
        store.state.Ente.configs.login_pubblico.stato &&
        store.state.Ente.configs.login_pubblico.stato == "obbligatorio" &&
        !Cookie.get("user") &&
        to.name != "Login"
    ) {
        router.push({
            name: "Login",
            params: {
                ente: to.params.ente
            }
        });
    }

    toggleLoading();
    store.commit("Utils/CLEAR_ERRORS");
    return next();
});

const DEFAULT_TITLE = "Portale Planning";
router.afterEach(to => {
    Vue.nextTick(() => {
        const pageName = to.meta.pageName;
        let title = store.state.Ente.cliente
            ? DEFAULT_TITLE + "-" + store.state.Ente.cliente.Nome
            : DEFAULT_TITLE;
        if (pageName) title = title + "-" + pageName;
        document.title = title;
    });
});

export default router;
