import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "it",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "it",
    messages: {} // Verranno caricati tramite l'API
});
