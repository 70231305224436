var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8 col-12 mt-5 mx-auto"},[(_vm.errors)?_c('error-banner',{attrs:{"close":""}}):_vm._e(),_c('div',{staticClass:"card card-outline-secondary border"},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("reset_password.title")))])]),_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"form",attrs:{"method":"POST","action":"/","role":"form","autocomplete":"off"}},[_c('div',{staticClass:"form-group",class:{ 'form-group--error': _vm.$v.password.$error }},[_c('span',{staticClass:"label",attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t("reset_password.new_password")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"password","id":"password","name":"password","required":"","pattern":"^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$"},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('password',{staticClass:"w-full",attrs:{"strength-meter-only":true},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('VDropdown',{attrs:{"distance":6},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('ul',{staticClass:"list-group list-group-flush"},[_c('li',{staticClass:"list-group-item"},[_vm._v(" "+_vm._s(_vm.$t("reset_password.min_length"))+" "),_c('span',{staticClass:"ml-2"},[(
                                                    !_vm.$v.password.minLength
                                                )?_c('i',{staticClass:"fas fa-times text-danger"}):_c('i',{staticClass:"fa fa-check text-success"})])]),_c('li',{staticClass:"list-group-item"},[_vm._v(" "+_vm._s(_vm.$t( "reset_password.capital_letters" ))+" "),_c('span',{staticClass:"ml-2"},[(
                                                    !_vm.$v.password
                                                        .mustHaveCapitalizedLetter
                                                )?_c('i',{staticClass:"fas fa-times text-danger"}):_c('i',{staticClass:"fa fa-check text-success"})])]),_c('li',{staticClass:"list-group-item"},[_vm._v(" "+_vm._s(_vm.$t("reset_password.numbers"))+" "),_c('span',{staticClass:"ml-2"},[(
                                                    !_vm.$v.password
                                                        .mustHaveNumber
                                                )?_c('i',{staticClass:"fas fa-times text-danger"}):_c('i',{staticClass:"fa fa-check text-success"})])]),_c('li',{staticClass:"list-group-item"},[_vm._v(" "+_vm._s(_vm.$t( "reset_password.special_characters" ))+" "),_c('span',{staticClass:"ml-2"},[(
                                                    !_vm.$v.password
                                                        .mustHaveSpecialCharacter
                                                )?_c('i',{staticClass:"fas fa-times text-danger"}):_c('i',{staticClass:"fa fa-check text-success"})])]),_c('li',{staticClass:"list-group-item"},[_vm._v(" "+_vm._s(_vm.$t( "reset_password.no_user_name" ))+" ")])])]},proxy:true}])},[_c('span',{staticClass:"badge badge-pill  ",class:+_vm.$v.password.$invalid
                                        ? 'badge-danger'
                                        : 'badge-success'},[_c('i',{staticClass:"fa fa-info p-1"})])])],1),(_vm.$v.password.$dirty)?_c('div',[(!_vm.$v.password.required)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("errors.required"))+" ")]):_vm._e(),(!_vm.$v.password.minLength)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("errors.min_password"))+" ")]):_vm._e(),(!_vm.$v.password.mustHaveSpecialCharacter)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("reset_password.special_characters"))+" ")]):_vm._e(),(!_vm.$v.password.mustHaveNumber)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("reset_password.numbers"))+" ")]):_vm._e(),(!_vm.$v.password.mustHaveCapitalizedLetter)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("reset_password.capital_letters"))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group",class:{
                        'form-group--error': _vm.$v.repeatPassword.$error
                    }},[_c('span',{staticClass:"label",attrs:{"for":"inputPasswordNewVerify"}},[_vm._v(_vm._s(_vm.$t("reset_password.verify_password")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.repeatPassword.$model),expression:"$v.repeatPassword.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"password","id":"confirm_password","required":""},domProps:{"value":(_vm.$v.repeatPassword.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.repeatPassword, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.repeatPassword.sameAsPassword)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("errors.password_not_match"))+" ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-lg btn-block",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.confirm"))+" ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }